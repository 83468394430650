import { defineComponent } from 'vue';
import RecruitmentStageComponent from '@/presentation/pages/recruitment-stage/recruitment-stage-class';

export default defineComponent({
	setup: () => {
		const component = new RecruitmentStageComponent();

		return {
			stages: component.stages,
			setStage: component.setStage.bind(component),
			isStageSelected: component.isStageSelected.bind(component)
		};
	}
});
