import { Component, computed } from 'vue';
import { TRoutes, Routes, IRouter } from '@/models/router.interface';
import RedshiftTracker from '@/infrastructure/redshift-tracker';
import Start from '@/presentation/pages/start/start.vue';
import RecruitmentStage from '@/presentation/pages/recruitment-stage/recruitment-stage.vue';
import IntakePeriod from '@/presentation/pages/intake-period/intake-period.vue';
import Upload from '@/presentation/pages/upload/upload.vue';
import Matching from '@/presentation/pages/matching/matching.vue';
import End from '@/presentation/pages/end/end.vue';
import store from '@/store';

export default class AppComponent {
	private routes: TRoutes = {
		[Routes.START]: Start as Component,
		[Routes.RECRUITMENT_STAGE]: RecruitmentStage as Component,
		[Routes.INTAKE_PERIOD]: IntakePeriod as Component,
		[Routes.UPLOAD]: Upload as Component,
		[Routes.MATCHING]: Matching as Component,
		[Routes.END]: End as Component
	};

	private get redshiftTracker(): RedshiftTracker {
		return store.getters.redshiftTracker();
	}

	public standAloneLogin = computed((): boolean => {
		return store.getters.standAloneLogin();
	});

	public router = computed((): IRouter => {
		return store.getters.router();
	});

	public currentRoute = computed((): string => {
		return this.router.value.current;
	});

	// eslint-disable-next-line @typescript-eslint/naming-convention
	public CurrentComponent = computed((): Component => {
		return this.routes[this.router.value.current];
	});

	public mounted(): void {
		this.redshiftTracker.trackImpression();
	}
}
