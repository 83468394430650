import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "UploadFailedOverview" }
const _hoisted_2 = { class: "SMTCardWrapper InvalidUploadDetails" }
const _hoisted_3 = { class: "SMTCard" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "SMTCard SMTCardInvalidRecords"
}
const _hoisted_6 = { class: "SMTCard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InvalidRecordsOverview = _resolveComponent("InvalidRecordsOverview")!
  const _component_UploadButton = _resolveComponent("UploadButton")!
  const _component_UploadVerifyNote = _resolveComponent("UploadVerifyNote")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "SMTTitle" }, " Invalid file ", -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("i", { class: "lnr-notification-circle InvalidUploadDetailsIcon" }, null, -1 /* HOISTED */)),
        _createElementVNode("p", {
          class: "SMTCardText SMTTextSmall",
          innerHTML: _ctx.validationMessage
        }, null, 8 /* PROPS */, _hoisted_4)
      ]),
      (_ctx.areInvalidRecordsAvailable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_InvalidRecordsOverview)
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_6, [
        _cache[1] || (_cache[1] = _createElementVNode("i", { class: "lnr-redo2 InvalidUploadDetailsIcon" }, null, -1 /* HOISTED */)),
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "SMTCardText SMTTextSmall" }, " Submit it here ", -1 /* HOISTED */)),
        _createVNode(_component_UploadButton)
      ]),
      _createVNode(_component_UploadVerifyNote)
    ])
  ]))
}