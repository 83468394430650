import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "StudentMatchingToolClients" }
const _hoisted_2 = { class: "SMTWhiteBlock" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavigationStatus = _resolveComponent("NavigationStatus")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_FAQ = _resolveComponent("FAQ")!
  const _component_LogOut = _resolveComponent("LogOut")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_NavigationStatus),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.CurrentComponent), _mergeProps(_ctx.router.props, { class: "RouterView" }), null, 16 /* FULL_PROPS */)),
      _createVNode(_component_Navigation)
    ]),
    _createVNode(_component_FAQ),
    (_ctx.standAloneLogin)
      ? (_openBlock(), _createBlock(_component_LogOut, { key: 0 }))
      : _createCommentVNode("v-if", true)
  ]))
}