import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "PageUpload",
  class: "SMTPage"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadFailedOverview = _resolveComponent("UploadFailedOverview")!
  const _component_UploadOverview = _resolveComponent("UploadOverview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isUploadInvalid)
      ? (_openBlock(), _createBlock(_component_UploadFailedOverview, { key: 0 }))
      : (_openBlock(), _createBlock(_component_UploadOverview, { key: 1 }))
  ]))
}