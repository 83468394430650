import { ValidationErrors } from '@/models/tracking/validation-errors.enum';
import { IValidationRule } from '@/models/validation/validation-rule.interface';
import { IUpload } from '@/models/upload.interface';

export default new (class FileIsCsvRule implements IValidationRule {
	private fileType = '';
	public trackingValidationError = ValidationErrors.NOT_CSV;

	public get errorMessage(): string {
		const fileTypePart = this.fileType ? `a ".${this.fileType}" file` : `the wrong file type`;
		const upperMessage = `You submitted ${fileTypePart}. <strong>Only ".csv" files are supported.</strong>`;
		const lowerMessage = `Please check your file and submit it again.`;
		return `${upperMessage} ${lowerMessage}`;
	}

	public doesUploadBreakRule(upload: IUpload): boolean {
		this.fileType = upload.fileType;
		return this.fileType !== 'csv';
	}
})();
