import { defineComponent } from 'vue';
import LogOutComponent from '@/stand-alone/log-out/log-out-class';

export default defineComponent({
	setup: () => {
		const component = new LogOutComponent();

		return {
			hideWidget: component.hideWidget.bind(component),
			signOut: component.signOut.bind(component)
		};
	}
});
