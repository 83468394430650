import { ILink } from '@/models/link.interface';

class StylesheetLoader {
	public static loadStylesheet(url: string): void {
		const linkTags = Array.prototype.slice.call(document.getElementsByTagName('link')).filter((link: ILink) => {
			return link.href.indexOf(url) !== -1;
		});

		if (linkTags.length === 0) {
			const head = document.head || document.getElementsByTagName('head')[0];
			const link = document.createElement('link');
			link.rel = 'stylesheet';
			link.href = url;

			head.appendChild(link);
		}
	}
}

export default StylesheetLoader;
