import { computed, ref, Ref } from 'vue';
import { Months } from '@/models/months.enum';
import { IMultiSelect, IOption } from '@/models/multiselect.interface';
import store from '@/store';

export default class IntakePeriodComponent {
	public intakeMonthMultiSelect: Ref<IMultiSelect | null> = ref(null);
	public intakeYearMultiSelect: Ref<IMultiSelect | null> = ref(null);

	public get months(): IOption[] {
		return Object.values(Months).map((name, index) => ({
			label: name,
			value: index + 1
		}));
	}

	public get years(): IOption[] {
		const newestYear = this.newestIntakeYear;
		const oldestYear = 2016;
		const yearData: IOption[] = [];

		// Prepare an array of year objects from newest to oldest with the expected multi-select options structure.
		for (let year = newestYear; year >= oldestYear; year--) {
			yearData.push({
				label: year.toString(),
				value: year
			});
		}

		return yearData;
	}

	private get newestIntakeYear(): number {
		const date = new Date();
		const year = date.getFullYear();

		// From September onwards, the next year is included.
		return date.getMonth() > 7 ? year + 1 : year;
	}

	private intakeMonth = computed((): number | undefined => {
		return store.getters.intakeMonth();
	});

	private intakeYear = computed((): number | undefined => {
		return store.getters.intakeYear();
	});

	public mounted(): void {
		this.prefillIntakeMonth();
		this.prefillIntakeYear();
	}

	public setMonth(answer: IOption): void {
		store.mutations.setIntakeMonth(answer.value as number);
	}

	public setYear(answer: IOption): void {
		store.mutations.setIntakeYear(answer.value as number);
	}

	private prefillIntakeMonth(): void {
		const intakeMonth = this.intakeMonth.value;
		if (!intakeMonth || !this.intakeMonthMultiSelect.value) {
			return;
		}

		const selectedOption = this.months.find((month) => month.value === intakeMonth);
		if (!selectedOption) {
			return;
		}

		this.intakeMonthMultiSelect.value.selectedOption = selectedOption;
	}

	private prefillIntakeYear(): void {
		const intakeYear = this.intakeYear.value;
		if (!intakeYear || !this.intakeYearMultiSelect.value) {
			return;
		}

		const selectedOption = this.years.find((year) => year.value === intakeYear);
		if (!selectedOption) {
			return;
		}

		this.intakeYearMultiSelect.value.selectedOption = selectedOption;
	}
}
