import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "UploadOverview" }
const _hoisted_2 = { class: "SMTTitle" }
const _hoisted_3 = { class: "SMTText" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "SMTCard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UploadButton = _resolveComponent("UploadButton")!
  const _component_UploadVerifyNote = _resolveComponent("UploadVerifyNote")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.pageTitle), 1 /* TEXT */),
    _createElementVNode("p", _hoisted_3, [
      _cache[0] || (_cache[0] = _createTextVNode(" Submit student data ")),
      _createElementVNode("span", { innerHTML: _ctx.recruitmentStageAsText }, null, 8 /* PROPS */, _hoisted_4),
      _createElementVNode("span", { innerHTML: _ctx.intakePeriodAsText }, null, 8 /* PROPS */, _hoisted_5),
      _cache[1] || (_cache[1] = _createTextVNode(". Follow the steps below to make sure that you prepare the file in the right format. "))
    ]),
    _createElementVNode("div", {
      class: _normalizeClass([
			'SMTCardWrapper',
			_ctx.upload ? 'ValidUploadDetails' : 'AwaitingUpload'
		])
    }, [
      _cache[3] || (_cache[3] = _createStaticVNode("<div class=\"SMTCard\"><p class=\"SMTCardText SMTTextSmall\"> Create an Excel file that contains <strong>all known <em>(non-institutional)</em> email addresses</strong>. If you have multiple emails per enrolment, <strong>include them all.</strong><span class=\"SMTAttentionTag\">Important!</span></p></div><div class=\"SMTCard\"><p class=\"SMTCardText SMTTextSmall\"> Please make sure only the first column contains information. </p></div><div class=\"SMTCard\"><p class=\"SMTCardText SMTTextSmall\"> Save your Excel as a <strong>.CSV</strong> file. </p></div>", 3)),
      _createElementVNode("div", _hoisted_6, [
        _cache[2] || (_cache[2] = _createElementVNode("p", { class: "SMTCardText SMTTextSmall" }, " Submit the file here ", -1 /* HOISTED */)),
        _createVNode(_component_UploadButton)
      ]),
      (!_ctx.upload)
        ? (_openBlock(), _createBlock(_component_UploadVerifyNote, { key: 0 }))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */)
  ]))
}