import { ValidationErrors } from '@/models/tracking/validation-errors.enum';
import { IValidationRule } from '@/models/validation/validation-rule.interface';
import { IUpload } from '@/models/upload.interface';

export default new (class NotTooManyRowsRule implements IValidationRule {
	public trackingValidationError = ValidationErrors.TOO_MANY_ROWS;
	private rowCount = 0;
	private maxRows = 13000;

	public get errorMessage(): string {
		const rowCountAsString = this.rowCount.toLocaleString();
		const maxAsString = this.maxRows.toLocaleString();
		const upperMessage = `Your file has ${rowCountAsString} rows. <strong>The maximum is ${maxAsString} rows.</strong>`;
		const lowerMessage = `Please split it up in multiple files and submit them separately.`;
		return `${upperMessage} ${lowerMessage}`;
	}

	public doesUploadBreakRule(upload: IUpload): boolean {
		this.rowCount = upload.records.length;
		return this.rowCount > this.maxRows;
	}
})();
