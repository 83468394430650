import { computed, ref, Ref } from 'vue';
import { UploadTrackingOptions } from '@/models/tracking/upload-tracking.enum';
import { IUpload } from '@/models/upload.interface';
import { TStringArrayPromiseResolve } from '@/models/promise-units.interface';
import PromiseHelper from '@/services/promise-helper';
import ValidationHandler from '@/services/validation/validation-handler';
import RedshiftTracker from '@/infrastructure/redshift-tracker';
import store from '@/store';

export default class UploadButtonComponent {
	public uploadButton: Ref<HTMLInputElement | null> = ref(null);
	private fileReader = new FileReader();

	private get validationHandler(): ValidationHandler {
		return store.getters.validationHandler();
	}

	private get redshiftTracker(): RedshiftTracker {
		return store.getters.redshiftTracker();
	}

	public isUploadValid = computed((): boolean => {
		return this.upload.value !== undefined && this.upload.value.validation === undefined;
	});

	public upload = computed((): IUpload | undefined => {
		return store.getters.upload();
	});

	public async processUpload(event: Event): Promise<void> {
		event.preventDefault();

		const file = this.retrieveUploadedFile();
		if (!file) {
			return;
		}

		this.trackUploadFileClick();
		await this.setUpload(file);
	}

	private retrieveUploadedFile(): File | undefined {
		if (!this.uploadButton.value) {
			return;
		}

		const files = this.uploadButton.value.files;
		if (!files || !files.length) {
			return;
		}

		return files[0];
	}

	private async setUpload(file: File): Promise<void> {
		const title = file.name;
		const fileType = this.retrieveFileExtension(title);
		const records = await this.retrieveRecordsFromFile(file);
		const upload = this.validationHandler.validate({
			title,
			fileType,
			records
		});

		store.mutations.setUpload(upload);
	}

	private async retrieveRecordsFromFile(file: File): Promise<string[]> {
		const promiseData = PromiseHelper.createStringArrayPromise();

		this.fileReader.readAsText(file);
		this.readFileReaderResponse(promiseData.resolve);

		return promiseData.promise;
	}

	private readFileReaderResponse(resolve: TStringArrayPromiseResolve): void {
		this.fileReader.onload = (): void => {
			const content = this.fileReader.result;
			if (!content) {
				void resolve([]);
				return;
			}

			const records = (content as string).split(/\r\n|\r|\n/g);
			void resolve(records);
		};
	}

	private retrieveFileExtension(fileName: string): string {
		const index = fileName.lastIndexOf('.');
		// Handle files that start with a dot (e.g. .htaccess).
		return index < 1 ? '' : fileName.substr(index + 1);
	}

	private trackUploadFileClick(): void {
		let trackingOption = UploadTrackingOptions.NEW;

		if (this.upload.value) {
			trackingOption = this.upload.value.validation ? UploadTrackingOptions.FIX : UploadTrackingOptions.CHANGE;
		}

		this.redshiftTracker.trackUploadFileClick(trackingOption);
	}
}
