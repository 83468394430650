import { defineComponent } from 'vue';
import MatchingProgressOverviewComponent from '@/presentation/components/matching-progress-overview/matching-progress-overview-class';

export default defineComponent({
	setup: () => {
		const component = new MatchingProgressOverviewComponent();

		return {
			anonymisingInProgress: component.anonymisingInProgress,
			anonymisingFailed: component.anonymisingFailed,
			submittingInProgress: component.submittingInProgress,
			submittingFailed: component.submittingFailed,
			matchingStatusText: component.matchingStatusText,
			matchingInProgress: component.matchingInProgress
		};
	}
});
