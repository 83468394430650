import { computed } from 'vue';
import { IUpload } from '@/models/upload.interface';
import store from '@/store';

export default class UploadFailedOverviewComponent {
	public validationMessage = computed((): string => {
		if (!this.upload.value || !this.upload.value.validation) {
			return '';
		}

		return this.upload.value.validation.message;
	});

	public areInvalidRecordsAvailable = computed((): boolean => {
		if (!this.upload.value || !this.upload.value.validation) {
			return false;
		}

		return this.upload.value.validation.invalidRecords.length > 0;
	});

	public upload = computed((): IUpload | undefined => {
		return store.getters.upload();
	});
}
