import { defineComponent } from 'vue';
import UploadButtonComponent from '@/presentation/components/upload-button/upload-button-class';

export default defineComponent({
	setup: () => {
		const component = new UploadButtonComponent();

		return {
			uploadButton: component.uploadButton,
			upload: component.upload,
			isUploadValid: component.isUploadValid,
			processUpload: component.processUpload.bind(component)
		};
	}
});
