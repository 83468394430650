import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "PageIntakePeriod",
  class: "SMTPage"
}
const _hoisted_2 = { class: "SMTPageContentBlock" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "SMTTitle" }, " Intake period ", -1 /* HOISTED */)),
      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "SMTText" }, " Select the intake period of the enrolments to verify. ", -1 /* HOISTED */)),
      _createVNode(_component_MultiSelect, {
        ref: "intakeMonthMultiSelect",
        class: "IntakeMultiSelect",
        "data-cy": "DropdownMonth",
        label: "Month",
        options: _ctx.months,
        onUpdated: _ctx.setMonth
      }, null, 8 /* PROPS */, ["options", "onUpdated"]),
      _createVNode(_component_MultiSelect, {
        ref: "intakeYearMultiSelect",
        class: "IntakeMultiSelect",
        "data-cy": "DropdownYear",
        label: "Year",
        options: _ctx.years,
        onUpdated: _ctx.setYear
      }, null, 8 /* PROPS */, ["options", "onUpdated"]),
      _cache[2] || (_cache[2] = _createElementVNode("p", { class: "SMTText SMTTextSmall" }, " In case of multiple intake periods, submit them separately per intake. ", -1 /* HOISTED */))
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "SMTPageContentBlock" }, [
      _createElementVNode("img", {
        class: "SMTPageImage",
        src: "https://monet-prtl-co.imgix.net/Endor/SMT/intake-period-visual.svg"
      })
    ], -1 /* HOISTED */))
  ]))
}