import { defineComponent, onMounted, onUnmounted } from 'vue';
import ReviewUploadModalComponent from '@/presentation/components/review-upload-modal/review-upload-modal-class';

export default defineComponent({
	setup: () => {
		const component = new ReviewUploadModalComponent();

		onMounted(component.mounted.bind(component));
		onUnmounted(component.unmounted.bind(component));

		return {
			geoScopeOptions: component.geoScopeOptions,
			commissionTypeOptions: component.commissionTypeOptions,
			fileContentsOptions: component.fileContentsOptions,
			modalContent: component.modalContent,
			selectedGeoScope: component.selectedGeoScope,
			selectedCommissionType: component.selectedCommissionType,
			selectedFileContents: component.selectedFileContents,
			numberOfUniqueEnrolments: component.numberOfUniqueEnrolments,
			modalReviewUploadState: component.modalReviewUploadState,
			isModalStepUploadScopeAndType: component.isModalStepUploadScopeAndType,
			isModalStepUploadContents: component.isModalStepUploadContents,
			isSelectedFileContentsMultiple: component.isSelectedFileContentsMultiple,
			closeModal: component.closeModal.bind(component),
			showUploadScopeAndTypeScreen: component.showUploadScopeAndTypeScreen.bind(component),
			showUploadContentsScreen: component.showUploadContentsScreen.bind(component)
		};
	}
});
