import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  id: "PageEnd",
  class: "SMTPage"
}
const _hoisted_2 = { class: "SMTPageContentBlock" }
const _hoisted_3 = { class: "SMTText" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "SMTText" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "SMTPageContentBlock" }
const _hoisted_10 = {
  key: 0,
  class: "SMTPageImage",
  src: "https://monet-prtl-co.imgix.net/Endor/SMT/final-screen-visual.svg"
}
const _hoisted_11 = {
  key: 1,
  class: "SMTPageImage NoMatch",
  src: "https://monet-prtl-co.imgix.net/Endor/SMT/no-matches-found.svg"
}
const _hoisted_12 = {
  key: 2,
  class: "SMTPageImage SubmitFailed",
  src: "https://monet-prtl-co.imgix.net/Endor/SMT/file-submit-failed.svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.matchesFound)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _cache[3] || (_cache[3] = _createElementVNode("h1", { class: "SMTTitle" }, "Thank you!", -1 /* HOISTED */)),
            _createElementVNode("p", _hoisted_3, [
              _cache[1] || (_cache[1] = _createTextVNode(" We appreciate the time you took to share ")),
              _createElementVNode("span", { innerHTML: _ctx.recruitmentStageAsText }, null, 8 /* PROPS */, _hoisted_4),
              _createElementVNode("span", { innerHTML: _ctx.intakePeriodAsText }, null, 8 /* PROPS */, _hoisted_5),
              _cache[2] || (_cache[2] = _createTextVNode(". Your Partnership Success Manager will be in touch with you about the matches as soon as the internal review is done. "))
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "WarningContainer" }, [
              _createElementVNode("i", { class: "lnr-info WarningIcon" }),
              _createElementVNode("div", { class: "WarningMessage" }, " Please don't forget to upload secondary emails of your students. ")
            ], -1 /* HOISTED */)),
            _cache[5] || (_cache[5] = _createElementVNode("p", { class: "SMTText" }, " Do you want to submit one more file? ", -1 /* HOISTED */))
          ], 64 /* STABLE_FRAGMENT */))
        : (_ctx.noMatchesFound)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _cache[8] || (_cache[8] = _createElementVNode("h1", { class: "SMTTitle" }, " No matches found ", -1 /* HOISTED */)),
              _createElementVNode("p", _hoisted_6, [
                _cache[6] || (_cache[6] = _createTextVNode(" We appreciate the time you took to share ")),
                _createElementVNode("span", { innerHTML: _ctx.recruitmentStageAsText }, null, 8 /* PROPS */, _hoisted_7),
                _createElementVNode("span", { innerHTML: _ctx.intakePeriodAsText }, null, 8 /* PROPS */, _hoisted_8),
                _cache[7] || (_cache[7] = _createTextVNode(". Unfortunately no matches were found. Please check the file that you submitted to verify that this outcome is correct. "))
              ])
            ], 64 /* STABLE_FRAGMENT */))
          : (_ctx.submitFailed)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                _cache[9] || (_cache[9] = _createElementVNode("h1", { class: "SMTTitle" }, " Oops! Submit failed! ", -1 /* HOISTED */)),
                _cache[10] || (_cache[10] = _createElementVNode("p", { class: "SMTText" }, " Something went wrong with submitting your file. Please try again. If the problem persists, please contact your Partnership Success Manager. ", -1 /* HOISTED */))
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
      _createElementVNode("button", {
        class: "ChampionButton",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.refreshTool && _ctx.refreshTool(...args)))
      }, " Start matching again! ")
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_ctx.matchesFound)
        ? (_openBlock(), _createElementBlock("img", _hoisted_10))
        : (_ctx.noMatchesFound)
          ? (_openBlock(), _createElementBlock("img", _hoisted_11))
          : (_openBlock(), _createElementBlock("img", _hoisted_12))
    ])
  ]))
}