import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  id: "PageStart",
  class: "SMTPage"
}
const _hoisted_2 = { class: "SMTPageContentBlock" }
const _hoisted_3 = {
  key: 0,
  class: "OrganisationSelectWrapper SMTText"
}
const _hoisted_4 = { class: "SelectedOrganisationText" }
const _hoisted_5 = {
  key: 1,
  class: "OrganisationSelectWrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "SMTTitle" }, " Welcome to the Student Matching Tool! ", -1 /* HOISTED */)),
      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "SMTText" }, " Automatically and safely verify students who found your institution via Studyportals based on the email addresses they used. ", -1 /* HOISTED */)),
      (_ctx.isOnlyOneOrganisationAvailable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _cache[0] || (_cache[0] = _createElementVNode("p", { class: "OrganisationSelectedText" }, " Students will be matched for the following organisation: ", -1 /* HOISTED */)),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.organisationDetails.organisations[0].name), 1 /* TEXT */)
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _cache[1] || (_cache[1] = _createElementVNode("p", { class: "SMTText OrganisationSelectText" }, " To get started, please select the institution that the enrolments apply to: ", -1 /* HOISTED */)),
            _createVNode(_component_MultiSelect, {
              ref: "organisationMultiSelect",
              class: "OrganisationMultiSelect",
              "data-cy": "DropdownOrganisation",
              label: "Organisation",
              options: _ctx.organisationOptions,
              onUpdated: _ctx.selectOrganisation
            }, null, 8 /* PROPS */, ["options", "onUpdated"])
          ]))
    ]),
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "SMTPageContentBlock" }, [
      _createElementVNode("img", {
        class: "SMTPageImage",
        src: "https://monet-prtl-co.imgix.net/Endor/SMT/start-visual.svg"
      })
    ], -1 /* HOISTED */))
  ]))
}