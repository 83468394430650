import { Component, defineComponent } from 'vue';
import UploadOverview from '@/presentation/components/upload-overview/upload-overview.vue';
import UploadFailedOverview from '@/presentation/components/upload-failed-overview/upload-failed-overview.vue';
import UploadComponent from '@/presentation/pages/upload/upload-class';

export default defineComponent({
	components: {
		UploadOverview: UploadOverview as Component,
		UploadFailedOverview: UploadFailedOverview as Component
	},
	setup: () => {
		const component = new UploadComponent();

		return {
			isUploadInvalid: component.isUploadInvalid
		};
	}
});
