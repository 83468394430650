import { defineComponent, onMounted } from 'vue';
import EndComponent from '@/presentation/pages/end/end-class';

export default defineComponent({
	setup: () => {
		const component = new EndComponent();

		onMounted(component.mounted.bind(component));

		return {
			matchesFound: component.matchesFound,
			noMatchesFound: component.noMatchesFound,
			submitFailed: component.submitFailed,
			recruitmentStageAsText: component.recruitmentStageAsText,
			intakePeriodAsText: component.intakePeriodAsText,
			refreshTool: component.refreshTool.bind(component)
		};
	}
});
