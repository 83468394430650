import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { id: "MatchingProgressOverview" }
const _hoisted_2 = { class: "SMTTitle" }
const _hoisted_3 = { class: "SMTCardWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.matchingStatusText), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass([
				'SMTCard',
				_ctx.anonymisingInProgress ? 'Loading' : ''
			])
      }, [
        (_ctx.anonymisingFailed)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _cache[0] || (_cache[0] = _createElementVNode("div", { class: "MatchingProgressIconWrapper" }, [
                _createElementVNode("i", { class: "lnr-notification-circle MatchingProgressIcon Failure" })
              ], -1 /* HOISTED */)),
              _cache[1] || (_cache[1] = _createElementVNode("p", { class: "SMTCardText SMTTextSmall" }, " Anonymisation of enrolment information failed ", -1 /* HOISTED */))
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _cache[2] || (_cache[2] = _createElementVNode("div", {
                class: "MatchingProgressIconWrapper",
                "data-cy": "CardContentAnonymisingDataSuccess"
              }, [
                _createElementVNode("i", { class: "lnr-lock MatchingProgressIcon Success" }),
                _createElementVNode("img", {
                  class: "MatchingProgressCheckmark",
                  src: "https://monet-prtl-co.imgix.net/Endor/SMT/matching-step-checkmark.svg"
                })
              ], -1 /* HOISTED */)),
              _cache[3] || (_cache[3] = _createElementVNode("p", { class: "SMTCardText SMTTextSmall" }, " Enrolment information is anonymised ", -1 /* HOISTED */))
            ], 64 /* STABLE_FRAGMENT */))
      ], 2 /* CLASS */),
      _createElementVNode("div", {
        class: _normalizeClass([
				'SMTCard',
				_ctx.submittingInProgress ? 'Loading' : ''
			])
      }, [
        (_ctx.submittingFailed)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "MatchingProgressIconWrapper" }, [
                _createElementVNode("i", { class: "lnr-warning MatchingProgressIcon Failure" })
              ], -1 /* HOISTED */)),
              _cache[5] || (_cache[5] = _createElementVNode("p", { class: "SMTCardText SMTTextSmall" }, " Submit of anonymised data to Studyportals' secure server failed ", -1 /* HOISTED */))
            ], 64 /* STABLE_FRAGMENT */))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _cache[6] || (_cache[6] = _createElementVNode("div", {
                class: "MatchingProgressIconWrapper",
                "data-cy": "CardContentSubmittingDataSuccess"
              }, [
                _createElementVNode("i", { class: "lnr-university MatchingProgressIcon Success" }),
                _createElementVNode("img", {
                  class: "MatchingProgressCheckmark",
                  src: "https://monet-prtl-co.imgix.net/Endor/SMT/matching-step-checkmark.svg"
                })
              ], -1 /* HOISTED */)),
              _cache[7] || (_cache[7] = _createElementVNode("p", { class: "SMTCardText SMTTextSmall" }, " Anonymised data is submitted to Studyportals' secure server ", -1 /* HOISTED */))
            ], 64 /* STABLE_FRAGMENT */))
      ], 2 /* CLASS */),
      _createElementVNode("div", {
        class: _normalizeClass([
				'SMTCard',
				_ctx.matchingInProgress ? 'Loading' : ''
			])
      }, _cache[8] || (_cache[8] = [
        _createElementVNode("div", {
          class: "MatchingProgressIconWrapper",
          "data-cy": "CardContentMatchingDataSuccess"
        }, [
          _createElementVNode("i", { class: "lnr-profile MatchingProgressIcon Success" }),
          _createElementVNode("img", {
            class: "MatchingProgressCheckmark",
            src: "https://monet-prtl-co.imgix.net/Endor/SMT/matching-step-checkmark.svg"
          })
        ], -1 /* HOISTED */),
        _createElementVNode("p", { class: "SMTCardText SMTTextSmall" }, " Data is matched to similarly anonymised data ", -1 /* HOISTED */)
      ]), 2 /* CLASS */)
    ])
  ]))
}