import { computed } from 'vue';
import { Routes } from '@/models/router.interface';
import store from '@/store';

export default class NavigationStatusComponent {
	public get steps(): string[] {
		return Object.values(Routes).filter((route) => route !== Routes.END);
	}

	public isCurrentRouteNavigationStep = computed((): boolean => {
		return this.indexOfCurrentRoute.value !== -1;
	});

	private indexOfCurrentRoute = computed((): number => {
		return this.steps.findIndex((name) => name === this.currentRoute.value);
	});

	private currentRoute = computed((): string => {
		const router = store.getters.router();
		return router.current;
	});

	public isStepSelected(step: string): boolean {
		return this.currentRoute.value === step;
	}
}
