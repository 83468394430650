import store from '@/store';
import { Months } from '@/models/months.enum';
import { IntakeStageType } from '@studyportals/sp-lurch-interface';

class UploadPresenter {
	public retrieveRecruitmentStageWithAmountAsText(): string {
		const upload = store.getters.upload();
		const amount = upload ? `${upload.records.length}&nbsp;` : '';
		return `<strong>${amount}Email addresses</strong>`;
	}

	public retrieveRecruitmentStageAsText(): string {
		const stage = store.getters.recruitmentStage();

		switch (stage) {
			case IntakeStageType.ACCEPTED:
				return 'Offers';
			case IntakeStageType.APPLICATION:
				return 'Applications';
			case IntakeStageType.LEAD:
				return 'Leads';
			default:
				return 'Enrolments';
		}
	}

	public retrieveIntakePeriodAsText(useAbbreviatedMonth: boolean): string {
		const year = store.getters.intakeYear();
		const month = store.getters.intakeMonth();
		if (!year || !month) {
			return '';
		}

		const monthAsText = this.retrieveMonthAsText(month, useAbbreviatedMonth);
		return `<strong>${monthAsText}&nbsp;${year}</strong>`;
	}

	private retrieveMonthAsText(monthNumber: number, useAbbreviatedMonth: boolean): string {
		const monthIndex = monthNumber - 1;
		const month = Object.values(Months)[monthIndex];
		// If requested, return only the first three letters of the month.
		return useAbbreviatedMonth ? month.substr(0, 3) : month;
	}
}

export default new UploadPresenter();
