export enum SubmitUploadStatuses {
	INACTIVE = 0,
	ANONYMISING = 1,
	ANONYMISING_FAILED = 2,
	SUBMITTING = 3,
	SUBMITTING_FAILED = 4,
	MATCHING = 5,
	AWAITING_RESULT = 6,
	NO_MATCHES_FOUND = 7,
	MATCHES_FOUND = 8
}
