import { computed } from 'vue';
import store from '@/store';
import { SubmitUploadStatuses } from '@/models/submit-upload-statuses.enum';

export default class MatchingProgressOverviewComponent {
	public anonymisingInProgress = computed((): boolean => {
		return this.submitUploadStatus.value <= SubmitUploadStatuses.ANONYMISING;
	});

	public anonymisingFailed = computed((): boolean => {
		return this.submitUploadStatus.value === SubmitUploadStatuses.ANONYMISING_FAILED;
	});

	public submittingInProgress = computed((): boolean => {
		return this.submitUploadStatus.value <= SubmitUploadStatuses.SUBMITTING;
	});

	public submittingFailed = computed((): boolean => {
		return this.submitUploadStatus.value === SubmitUploadStatuses.SUBMITTING_FAILED;
	});

	public matchingInProgress = computed((): boolean => {
		return this.submitUploadStatus.value <= SubmitUploadStatuses.MATCHING;
	});

	public matchingStatusText = computed((): string => {
		switch (this.submitUploadStatus.value) {
			case SubmitUploadStatuses.ANONYMISING:
				return 'Your student data is being anonymised ...';
			case SubmitUploadStatuses.SUBMITTING:
				return 'Sending to Studyportals\' secure server ...';
			case SubmitUploadStatuses.MATCHING:
				return 'Matching to similar data ...';
			case SubmitUploadStatuses.AWAITING_RESULT:
				return 'Wrapping up the process and redirecting ...';
			case SubmitUploadStatuses.ANONYMISING_FAILED:
			case SubmitUploadStatuses.SUBMITTING_FAILED:
				return 'Something went wrong';
			default:
				return '';
		}
	});

	private submitUploadStatus = computed((): SubmitUploadStatuses => {
		return store.getters.submitUploadStatus();
	});
}
