import { IStringArrayPromiseUnits, TStringArrayPromiseResolve } from '@/models/promise-units.interface';

export default class PromiseHelper {
	public static createStringArrayPromise(): IStringArrayPromiseUnits {
		// Set initial value (so TypeScript understands it has the right type) which is always overwritten with the resolve function.
		let resolve = ((response: string[]) => {}) as TStringArrayPromiseResolve; // eslint-disable-line
		// Create new Promise and store resolve function so it can be returned later.
		const promise: Promise<string[]> = new Promise((resolveFn) => (resolve = resolveFn as TStringArrayPromiseResolve));
		// Return both the promise and resolve function.
		return { promise, resolve };
	}
}
