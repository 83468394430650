interface IReviewUploadOption {
	label: string;
	value: string;
	id: string;
}

enum ReviewUploadQuestionCategories {
	GEO_SCOPE = 'geo_scope',
	COMMISSION_TYPE = 'commission_type',
	FILE_CONTENTS = 'file_contents',
	NUMBER_OF_ENROLMENTS = 'number_of_enrolments'
}

enum GeoScopeOptions {
	ALL = 'all',
	INTERNATIONAL = 'international',
	DOMESTIC = 'domestic',
	UNSURE = 'unsure'
}

enum CommissionTypeOptions {
	COMBINATION = 'combination',
	AGENT = 'agent',
	DIRECT = 'direct',
	UNSURE = 'unsure'
}

enum FileContentsOptions {
	ADDITIONAL = 'additional',
	MULTIPLE = 'multiple',
	ONE = 'one',
	UNSURE = 'unsure'
}

export {
	IReviewUploadOption,
	ReviewUploadQuestionCategories,
	GeoScopeOptions,
	CommissionTypeOptions,
	FileContentsOptions
}
