import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "UploadVerifyNote" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createTextVNode(" Verify the format of the student email addresses with the help of this ")),
    _createElementVNode("a", {
      href: "https://smt-resources.s3.eu-west-1.amazonaws.com/SMT-upload-verify-template.xlsx",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.trackDownloadTemplateFileClick()))
    }, "Template file"),
    _cache[2] || (_cache[2] = _createTextVNode(". "))
  ]))
}