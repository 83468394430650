import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "FrequentlyAskedQuestions" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "FAQIntroduction SMTWhiteBlock" }
const _hoisted_4 = {
  key: 0,
  class: "FAQContent"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "FAQAnswer SMTWhiteBlock" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 1,
  class: "SMTTransparentBlock"
}
const _hoisted_9 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isOnStartPage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[2] || (_cache[2] = _createElementVNode("img", {
              src: "https://monet-prtl-co.imgix.net/Endor/SMT/faq-light-bulb.svg",
              class: "FAQIntroductionImage"
            }, null, -1 /* HOISTED */)),
            _cache[3] || (_cache[3] = _createElementVNode("p", { class: "FAQIntroductionText" }, [
              _createTextVNode(" Below you can find a collection of frequently asked questions about the Student Matching Tool. If you have more questions please contact your Partnership Success Manager or email us via "),
              _createElementVNode("span", { class: "FAQIntroductionEmailAddress" }, "client-services@studyportals.com.")
            ], -1 /* HOISTED */)),
            _createElementVNode("i", {
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleContent && _ctx.toggleContent(...args))),
              class: _normalizeClass([
						'FAQToggleChevron',
						_ctx.contentOpened ? 'lnr-chevron-down' : 'lnr-chevron-up'
					])
            }, null, 2 /* CLASS */)
          ]),
          (_ctx.contentOpened)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (questionInfo, index) => {
                  return (_openBlock(), _createElementBlock(_Fragment, null, [
                    _createElementVNode("div", {
                      onClick: ($event: any) => (_ctx.updateSelectedQuestion(index)),
                      class: _normalizeClass([
							'FAQQuestion',
							 'SMTWhiteBlock',
							_ctx.selectedQuestion === index ? 'Selected' : ''
						])
                    }, _toDisplayString(questionInfo.question), 11 /* TEXT, CLASS, PROPS */, _hoisted_5),
                    _createElementVNode("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(questionInfo.answerParagraphs, (paragraph) => {
                        return (_openBlock(), _createElementBlock("p", {
                          innerHTML: paragraph,
                          onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.replaceUrls && _ctx.replaceUrls(...args)), ["prevent"]))
                        }, null, 8 /* PROPS */, _hoisted_7))
                      }), 256 /* UNKEYED_FRAGMENT */))
                    ])
                  ], 64 /* STABLE_FRAGMENT */))
                }), 256 /* UNKEYED_FRAGMENT */))
              ]))
            : _createCommentVNode("v-if", true)
        ]))
      : (_openBlock(), _createElementBlock("p", _hoisted_8, [
          _cache[4] || (_cache[4] = _createTextVNode(" For more information on the Student Matching Tool, visit ")),
          _createElementVNode("a", {
            href: _ctx.uniAdminUrlFAQ,
            target: "uniAdminFAQ"
          }, " our FAQ page ", 8 /* PROPS */, _hoisted_9)
        ]))
  ]))
}