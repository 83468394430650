import { defineComponent, onMounted } from 'vue';
import StartComponent from '@/presentation/pages/start/start-class';
import { MultiSelect } from '@studyportals/multiselect';

export default defineComponent({
	components: {
		MultiSelect
	},
	setup: () => {
		const component = new StartComponent();

		onMounted(component.mounted.bind(component));

		return {
			organisationMultiSelect: component.organisationMultiSelect,
			isOnlyOneOrganisationAvailable: component.isOnlyOneOrganisationAvailable,
			organisationDetails: component.organisationDetails,
			organisationOptions: component.organisationOptions,
			selectOrganisation: component.selectOrganisation.bind(component)
		};
	}
});
