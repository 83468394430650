import store from '@/store';
import RedshiftTracker from '@/infrastructure/redshift-tracker';

export default class UploadVerifyNoteComponent {
	private get redshiftTracker(): RedshiftTracker {
		return store.getters.redshiftTracker();
	}

	public trackDownloadTemplateFileClick(): void {
		this.redshiftTracker.trackDownloadTemplateFileClick();
	}
}
