import { IUserSessionOperationsProvider, UserSessionCookieManager } from '@studyportals/client-internal-platform-sso';

class UserSession {
	public readonly userSessionOperations: IUserSessionOperationsProvider;

	public constructor() {
		const domain = webpackDefinitions.VUE_APP_DOMAIN;
		const cognitoClientId = webpackDefinitions.VUE_APP_COGNITO_CLIENT_ID;
		const cognitoPoolId = webpackDefinitions.VUE_APP_COGNITO_USER_POOL_ID;

		this.userSessionOperations = new UserSessionCookieManager(cognitoClientId, cognitoPoolId, domain);
	}

	public keepAlive(): void {
		this.userSessionOperations.keepAlive();
	}

	public isValid(): boolean {
		return this.userSessionOperations.isSessionSetupAndNotExpired();
	}
}

export default new UserSession();
