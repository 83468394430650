import { computed } from 'vue';
import { IUpload } from '@/models/upload.interface';
import store from '@/store';

export default class UploadComponent {
	public isUploadInvalid = computed((): boolean => {
		return this.upload.value !== undefined && this.upload.value.validation !== undefined;
	});

	private upload = computed((): IUpload | undefined => {
		return store.getters.upload();
	});
}
