import { readonly } from 'vue';
import { IState, IStore } from '@/models/store/state.interface';

import state from './state';
import getters from './getters';
import mutations from './mutations';

const store: IStore = { state: readonly(state) as IState, mutations, getters };

export default store;
