import { defineComponent } from 'vue';
import InvalidRecordsOverviewComponent from '@/presentation/components/invalid-records-overview/invalid-records-overview-class';

export default defineComponent({
	setup: () => {
		const component = new InvalidRecordsOverviewComponent();

		return {
			invalidRecords: component.invalidRecords
		};
	}
});
