import { computed, ref, Ref } from 'vue';
import { IMultiSelect, IOption } from '@/models/multiselect.interface';
import { ISMTOrganisationDetails } from '@/models/organisation-details.interface';
import store from '@/store';

export default class StartComponent {
	public organisationMultiSelect: Ref<IMultiSelect | null> = ref(null);

	public organisationDetails = computed((): ISMTOrganisationDetails => {
		return store.getters.organisationDetails();
	});

	public isOnlyOneOrganisationAvailable = computed((): boolean => {
		return this.organisationDetails.value.organisations.length === 1;
	});

	public organisationOptions = computed((): IOption[] => {
		return this.organisationDetails.value.organisations.map((organisation) => ({
			label: organisation.name,
			value: organisation.identity
		}));
	});

	private isNoOrganisationSelected = computed((): boolean => {
		return this.organisationId.value.length === 0;
	});

	private organisationId = computed((): string => {
		return store.getters.organisationId();
	});

	public mounted(): void {
		this.prefillOrganisation();
	}

	public selectOrganisation(answer: IOption): void {
		store.mutations.setOrganisationId(answer.value as string);
	}

	private prefillOrganisation(): void {
		if (this.isOnlyOneOrganisationAvailable.value || this.isNoOrganisationSelected.value || !this.organisationMultiSelect.value) {
			return;
		}

		const selectedOption = this.organisationOptions.value.find(
			(option) => option.value === this.organisationId.value
		);
		if (!selectedOption) {
			return;
		}

		this.organisationMultiSelect.value.selectedOption = selectedOption;
	}
}
