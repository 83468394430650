import store from '@/store';
import { SubmitUploadStatuses } from '@/models/submit-upload-statuses.enum';
import { TSha512 } from '@/models/sha.interface';
import thingClient from '@/infrastructure/thing-client';
import SingleRecordValidationHandler from '@/services/validation/single-record-validation-handler';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const sha512 = require('js-sha512') as TSha512;

export default class EncryptionHandler {
	public static async encryptRecords(records: string[]): Promise<string> {
		EncryptionHandler.setSubmitUploadStatusAsAnonymising();

		try {
			const salt = await EncryptionHandler.retrieveSalt();
			records = records.map((record) => EncryptionHandler.encryptSingleRecord(record, salt));
		} catch (e) {
			EncryptionHandler.setSubmitUploadStatusAsFailure();
		}

		const recordsAsString = EncryptionHandler.convertRecordsToString(records);
		return EncryptionHandler.encodeRecordsStringAsBase64(recordsAsString);
	}

	private static async retrieveSalt(): Promise<string> {
		const organisationId = store.getters.organisationId();
		const salt = await thingClient.retrieveSaltKey(organisationId.toString());
		return salt.toLowerCase();
	}

	private static encryptSingleRecord(record: string, salt: string): string {
		record = record.toLowerCase();
		record = EncryptionHandler.hashRecordIfNotHashedYet(record);
		record = EncryptionHandler.saltRecord(record, salt);
		return EncryptionHandler.hashRecord(record);
	}

	private static hashRecordIfNotHashedYet(record: string): string {
		if (EncryptionHandler.isRecordHashed(record)) {
			return record;
		}

		return EncryptionHandler.hashRecord(record);
	}

	private static hashRecord(record: string): string {
		const hashedRecord = sha512(record);
		return hashedRecord.toLowerCase();
	}

	private static saltRecord(record: string, salt: string): string {
		return salt + record;
	}

	private static isRecordHashed(record: string): boolean {
		return !SingleRecordValidationHandler.isRecordEmail(record) && SingleRecordValidationHandler.isRecordHashed(record);
	}

	private static convertRecordsToString(records: string[]): string {
		return records.join('\n');
	}

	private static encodeRecordsStringAsBase64(data: string): string {
		return window.btoa(data);
	}

	private static setSubmitUploadStatusAsAnonymising(): void {
		store.mutations.setSubmitUploadStatus(SubmitUploadStatuses.ANONYMISING);
	}

	private static setSubmitUploadStatusAsFailure(): void {
		store.mutations.setSubmitUploadStatus(SubmitUploadStatuses.ANONYMISING_FAILED);
	}
}
