import { defineComponent } from 'vue';
import NavigationStatusComponent from '@/presentation/components/navigation-status/navigation-status-class';

export default defineComponent({
	setup: () => {
		const component = new NavigationStatusComponent();

		return {
			isCurrentRouteNavigationStep: component.isCurrentRouteNavigationStep,
			steps: component.steps,
			isStepSelected: component.isStepSelected.bind(component)
		};
	}
});
