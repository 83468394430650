import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { id: "SMTNavigationWrapper" }
const _hoisted_2 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isStepBackAvailable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.stepBack && _ctx.stepBack(...args))),
          class: "NavigationBackButton"
        }, _cache[3] || (_cache[3] = [
          _createElementVNode("i", { class: "lnr-arrow-left NavigationBackButtonIcon" }, null, -1 /* HOISTED */),
          _createTextVNode(" Back ")
        ])))
      : _createCommentVNode("v-if", true),
    (_ctx.isSkipAvailable)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.stepForward && _ctx.stepForward(...args))),
          class: "ChampionButton"
        }, " Skip "))
      : (_ctx.areStepsAvailable)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.stepForward && _ctx.stepForward(...args))),
            disabled: _ctx.isNextDisabled,
            class: "ChampionButton"
          }, _toDisplayString(_ctx.nextButtonText), 9 /* TEXT, PROPS */, _hoisted_2))
        : _createCommentVNode("v-if", true)
  ]))
}