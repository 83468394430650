import state from './state';
import { IGetters } from '@/models/store/state.interface';

const getters: IGetters = {
	intakeYear: () => {
		return state.intakeYear;
	},
	intakeMonth: () => {
		return state.intakeMonth;
	},
	modalReviewUploadState: () => {
		return state.modalReviewUploadState;
	},
	organisationDetails: () => {
		return state.organisationDetails;
	},
	organisationId: () => {
		return state.organisationId;
	},
	recruitmentStage: () => {
		return state.recruitmentStage;
	},
	redshiftTracker: () => {
		return state.redshiftTracker;
	},
	router: () => {
		return state.router;
	},
	standAloneLogin: () => {
		return state.standAloneLogin;
	},
	submitUploadStatus: () => {
		return state.submitUploadStatus;
	},
	upload: () => {
		return state.upload;
	},
	validationHandler: () => {
		return state.validationHandler;
	}
};

export default getters;
