import { defineComponent } from 'vue';
import LogInComponent from '@/stand-alone/log-in/log-in-class';

export default defineComponent({
	setup: () => {
		const component = new LogInComponent();

		return {
			signInClient: component.signInClient.bind(component)
		};
	}
});
