import state from './state';
import { IMutations } from '@/models/store/mutations.interface';
import { IntakeStageType } from '@studyportals/sp-lurch-interface';

const mutations: IMutations = {
	resetInput: () => {
		state.recruitmentStage = IntakeStageType.ENROLMENT;
		state.intakeMonth = undefined;
		state.intakeYear = undefined;
		state.upload = undefined;

		if (state.organisationDetails.organisations.length === 1) {
			return;
		}

		state.organisationId = '';
	},
	setCurrentRoute: (name, props = {}) => {
		state.router.current = name;
		state.router.props = props;
	},
	setIntakeYear: (year) => {
		state.intakeYear = year;
	},
	setIntakeMonth: (month) => {
		state.intakeMonth = month;
	},
	setModalReviewUploadState: (value) => {
		state.modalReviewUploadState = value;
	},
	setOrganisationDetails: (details) => {
		state.organisationDetails = details;
	},
	setOrganisationId: (id) => {
		state.organisationId = id;
	},
	setRecruitmentStage: (stage) => {
		state.recruitmentStage = stage;
	},
	setStandAloneLogin: (standAloneLogin) => {
		state.standAloneLogin = standAloneLogin;
	},
	setSubmitUploadStatus: (submitUploadStatus) => {
		state.submitUploadStatus = submitUploadStatus;
	},
	setUpload: (upload) => {
		state.upload = upload;
	}
};

export default mutations;
