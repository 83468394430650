import { Component, defineComponent } from 'vue';
import UploadButton from '@/presentation/components/upload-button/upload-button.vue';
import InvalidRecordsOverview from '@/presentation/components/invalid-records-overview/invalid-records-overview.vue';
import UploadVerifyNote from '@/presentation/components/upload-verify-note/upload-verify-note.vue';
import UploadFailedOverviewComponent from '@/presentation/components/upload-failed-overview/upload-failed-overview-class';

export default defineComponent({
	components: {
		UploadButton: UploadButton as Component,
		InvalidRecordsOverview: InvalidRecordsOverview as Component,
		UploadVerifyNote: UploadVerifyNote as Component
	},
	setup: () => {
		const component = new UploadFailedOverviewComponent();

		return {
			upload: component.upload,
			validationMessage: component.validationMessage,
			areInvalidRecordsAvailable: component.areInvalidRecordsAvailable
		};
	}
});
