import { App } from '@vue/runtime-core';

class RollbarOperator {
	ignoredWarnings: string[] = [];
	ignoredErrors: string[] = [];

	public handleVueErrorsAndWarnings(app: App): void {
		this.handleVueErrors(app);
		this.handleVueWarnings(app);
		this.handleGlobalErrors();
		this.handleGlobalWarnings();
	}

	public triggerError(message: Error | string, extra?: any): void {
		if (extra === undefined) {
			console.error(message);
		} else {
			console.error(message, extra);
		}

		if (!window.Rollbar) {
			return;
		}

		if (extra === undefined) {
			window.Rollbar.error(message);
		} else {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			window.Rollbar.error(message, extra);
		}
	}

	public triggerWarning(message: Error | string, extra?: any): void {
		if (extra === undefined) {
			console.warn(message);
		} else {
			console.warn(message, extra);
		}

		if (!window.Rollbar) {
			return;
		}

		if (extra === undefined) {
			window.Rollbar.warn(message);
		} else {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			window.Rollbar.warn(message, extra);
		}
	}

	private handleVueErrors(app: App): void {
		app.config.errorHandler = (error): void => {
			this.triggerError(error as Error);
			throw error;
		};
	}

	private handleVueWarnings(app: App): void {
		app.config.warnHandler = (warning): void => {
			this.triggerWarning(warning);
		};
	}

	private handleGlobalErrors(): void {
		const originalConsoleError = console.error;
		console.error = (message): void => {
			if (this.ignoredErrors.includes(message as string)) {
				return;
			}

			originalConsoleError(message);
		};
	}

	private handleGlobalWarnings(): void {
		const originalConsoleWarn = console.warn;
		console.warn = (message): void => {
			if (this.ignoredWarnings.includes(message as string)) {
				return;
			}

			originalConsoleWarn(message);
		};
	}
}

export default new RollbarOperator();
