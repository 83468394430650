import store from '@/store';
import { Product, Action } from '@studyportals/datalake-event-tracker';
import DataLakeEventTracker from '@/infrastructure/data-lake-event-tracker';
import { Routes } from '@/models/router.interface';
import { UploadTrackingOptions } from '@/models/tracking/upload-tracking.enum';
import { ValidationErrors } from '@/models/tracking/validation-errors.enum';
import { MatchingErrors } from '@/models/tracking/matching-errors.enum';
import { ReviewUploadQuestionCategories } from '@/models/review-upload.interface';

export default class RedshiftTracker {
	private tracker: DataLakeEventTracker;

	private get userIdAsNumber(): number {
		const details = store.getters.organisationDetails();
		return details.userId;
	}

	private get userIdAsString(): string {
		return this.userIdAsNumber.toString();
	}

	private get organisationId(): string {
		return store.getters.organisationId();
	}

	constructor() {
		this.tracker = new DataLakeEventTracker(Product.SMT);
	}

	public trackImpression(): void {
		this.trackEvent(
			Action.IMPRESSION,
			'open_tool',
			this.userIdAsString
		);
	}

	public trackBackClick(page: Routes | string): void {
		this.trackEvent(
			Action.CLICK,
			`back_${this.transformToSnakeCase(page)}`,
			this.userIdAsString
		);
	}

	public trackNextClick(page: Routes | string): void {
		this.trackEvent(
			Action.CLICK,
			`next_${this.transformToSnakeCase(page)}`,
			this.userIdAsString
		);
	}

	public trackUploadFileClick(option: UploadTrackingOptions): void {
		this.trackEvent(
			Action.CLICK,
			`upload_file_${option}`,
			this.userIdAsString
		);
	}

	public trackDownloadTemplateFileClick(): void {
		this.trackEvent(
			Action.CLICK,
			'download_template_file',
			this.userIdAsString
		);
	}

	public trackMatchingCompletion(): void {
		this.trackEvent(
			Action.NON_INTERACTIVE,
			'matching_completion',
			this.userIdAsString
		);
	}

	public trackRestartFlow(): void {
		this.trackEvent(
			Action.CLICK,
			'restart_flow',
			this.userIdAsString
		);
	}

	public trackValidationError(error: ValidationErrors, extra?: string): void {
		const label = `upload_error_${error}`;
		this.trackEvent(
			Action.NON_INTERACTIVE,
			extra ? `${label}_${extra}` : label,
			this.userIdAsString
		)
	}

	public trackMatchingError(error: MatchingErrors): void {
		this.trackEvent(
			Action.NON_INTERACTIVE,
			`matching_error_${error}`,
			this.userIdAsString
		);
	}

	public trackReviewUploadDetails(): void {
		this.trackEvent(
			Action.NON_INTERACTIVE,
			'review_upload_modal_details',
			this.organisationId,
			this.userIdAsNumber,
			true
		);
	}

	public trackReviewUploadModalOpened(): void {
		this.trackEvent(
			Action.POPUP_OPEN,
			'review_upload_modal_opened',
			'',
			this.userIdAsNumber,
			true
		);
	}

	public trackReviewUploadAnswer(category: ReviewUploadQuestionCategories, answer: string): void {
		this.trackEvent(
			Action.CLICK,
			`review_upload_answer_${category}`,
			answer,
			this.userIdAsNumber,
			true
		);
	}

	private trackEvent(action: Action, label: string, property: string, value?: number, trackInGA4 = false): void {
		if (!this.tracker.isTrackingFunctionalityAvailable) {
			return;
		}

		this.tracker.trackStructuredEvent(
			{
				action,
				label,
				property,
				value
			},
			Product.SMT,
			{
				enableSnowplow: !trackInGA4,
				enableGTM: trackInGA4
			}
		);
	}

	private transformToSnakeCase(input: string): string {
		const inputPieces = input.toLowerCase().split(' ');
		return inputPieces.join('_');
	}
}
