import { Component, defineComponent } from 'vue';
import UploadButton from '@/presentation/components/upload-button/upload-button.vue';
import UploadVerifyNote from '@/presentation/components/upload-verify-note/upload-verify-note.vue';
import UploadOverviewComponent from '@/presentation/components/upload-overview/upload-overview-class';

export default defineComponent({
	components: {
		UploadButton: UploadButton as Component,
		UploadVerifyNote: UploadVerifyNote as Component
	},
	setup: () => {
		const component = new UploadOverviewComponent();

		return {
			upload: component.upload,
			pageTitle: component.pageTitle,
			recruitmentStageAsText: component.recruitmentStageAsText,
			intakePeriodAsText: component.intakePeriodAsText
		};
	}
});
