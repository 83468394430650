import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "PageRecruitmentStage",
  class: "SMTPage"
}
const _hoisted_2 = { class: "SMTCardWrapper" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "SMTCardTitle" }
const _hoisted_6 = { class: "SMTCardText SMTTextSmall" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h1", { class: "SMTTitle" }, " Recruitment stage ", -1 /* HOISTED */)),
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "SMTText" }, " Select the stage of the students to match ", -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.stages, (stage) => {
        return (_openBlock(), _createElementBlock("div", {
          onClick: ($event: any) => (_ctx.setStage(stage.type)),
          class: _normalizeClass([
					'SMTCard',
					_ctx.isStageSelected(stage.type) ? 'Selected' : ''
				])
        }, [
          _createElementVNode("img", {
            class: "SMTCardImage",
            src: stage.image
          }, null, 8 /* PROPS */, _hoisted_4),
          _createElementVNode("p", _hoisted_5, _toDisplayString(stage.title), 1 /* TEXT */),
          _createElementVNode("p", _hoisted_6, _toDisplayString(stage.description), 1 /* TEXT */)
        ], 10 /* CLASS, PROPS */, _hoisted_3))
      }), 256 /* UNKEYED_FRAGMENT */))
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "SMTText SMTTextSmall SMTTextNote" }, " In case you want to submit students from multiple recruitment stages, you will need to submit them separately per recruitment stage. ", -1 /* HOISTED */))
  ]))
}