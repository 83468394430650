import { defineComponent, onMounted } from 'vue';
import IntakePeriodComponent from '@/presentation/pages/intake-period/intake-period-class';
import { MultiSelect } from '@studyportals/multiselect';

export default defineComponent({
	components: {
		MultiSelect
	},
	setup: () => {
		const component = new IntakePeriodComponent();

		onMounted(component.mounted.bind(component));

		return {
			intakeMonthMultiSelect: component.intakeMonthMultiSelect,
			intakeYearMultiSelect: component.intakeYearMultiSelect,
			months: component.months,
			years: component.years,
			setMonth: component.setMonth.bind(component),
			setYear: component.setYear.bind(component)
		};
	}
});
