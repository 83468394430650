import { defineComponent } from 'vue';
import UploadVerifyNoteComponent from '@/presentation/components/upload-verify-note/upload-verify-note-class';

export default defineComponent({
	setup: () => {
		const component = new UploadVerifyNoteComponent();

		return {
			trackDownloadTemplateFileClick: component.trackDownloadTemplateFileClick.bind(component)
		};
	}
});
