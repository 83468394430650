import { computed } from 'vue';
import { SubmitUploadStatuses } from '@/models/submit-upload-statuses.enum';
import { Routes } from '@/models/router.interface';
import { IUpload } from '@/models/upload.interface';
import { IntakeStageType } from '@studyportals/sp-lurch-interface';
import RedshiftTracker from '@/infrastructure/redshift-tracker';
import store from '@/store';
import SubmitUploadHandler from '@/services/submit-upload-handler';

export default class NavigationComponent {
	private get redshiftTracker(): RedshiftTracker {
		return store.getters.redshiftTracker();
	}

	private get routeNames(): string[] {
		return Object.values(Routes);
	}

	public nextButtonText = computed((): string => {
		if (this.isFirstScreen.value) {
			return "Let's get started";
		}

		if (this.isMatchingScreen.value) {
			return 'Confirm';
		}

		return 'Next';
	});

	public isNextDisabled = computed((): boolean => {
		return (
			this.isIntakePeriodRelevantAndMissing.value ||
			this.isValidUploadRelevantAndMissing.value ||
			this.isOrganisationRelevantAndMissing.value
		);
	});

	public isStepBackAvailable = computed((): boolean => {
		return this.areStepsAvailable.value && this.indexOfCurrentRoute.value > 0;
	});

	public areStepsAvailable = computed((): boolean => {
		return this.submitUploadStatus.value === SubmitUploadStatuses.INACTIVE;
	});

	public isSkipAvailable = computed((): boolean => {
		// For leads the intake period question can be skipped.
		return this.recruitmentStage.value === IntakeStageType.LEAD && this.currentRoute.value === Routes.INTAKE_PERIOD;
	});

	private isFirstScreen = computed((): boolean => {
		return this.currentRoute.value === Routes.START;
	});

	private isMatchingScreen = computed((): boolean => {
		return this.currentRoute.value === Routes.MATCHING;
	});

	private isOrganisationRelevantAndMissing = computed((): boolean => {
		return this.currentRoute.value === Routes.START && this.organisationId.value.length === 0;
	});

	private isIntakePeriodRelevantAndMissing = computed((): boolean => {
		return this.currentRoute.value === Routes.INTAKE_PERIOD && (!this.intakeYear.value || !this.intakeMonth.value);
	});

	private isValidUploadRelevantAndMissing = computed((): boolean => {
		return this.currentRoute.value === Routes.UPLOAD && (!this.upload.value || this.upload.value.validation !== undefined);
	});

	private indexOfCurrentRoute = computed((): number => {
		return this.routeNames.findIndex((name) => name === this.currentRoute.value);
	});

	private currentRoute = computed((): string => {
		const router = store.getters.router();
		return router.current;
	});

	private submitUploadStatus = computed((): SubmitUploadStatuses => {
		return store.getters.submitUploadStatus();
	});

	private organisationId = computed((): string => {
		return store.getters.organisationId();
	});

	private intakeYear = computed((): number | undefined => {
		return store.getters.intakeYear();
	});

	private intakeMonth = computed((): number | undefined => {
		return store.getters.intakeMonth();
	});

	private upload = computed((): IUpload | undefined => {
		return store.getters.upload();
	});

	private recruitmentStage = computed((): IntakeStageType => {
		return store.getters.recruitmentStage();
	});

	public stepBack(): void {
		this.redshiftTracker.trackBackClick(this.currentRoute.value);

		const previousRoute = this.routeNames[this.indexOfCurrentRoute.value - 1];
		store.mutations.setCurrentRoute(previousRoute as Routes);
	}

	public stepForward(): void {
		this.redshiftTracker.trackNextClick(this.currentRoute.value);

		if (this.isMatchingScreen.value) {
			void SubmitUploadHandler.handleUploadSubmit();
			return;
		}

		const nextRoute = this.routeNames[this.indexOfCurrentRoute.value + 1];
		store.mutations.setCurrentRoute(nextRoute as Routes);
	}
}
