import { ValidationErrors } from '@/models/tracking/validation-errors.enum';
import { IValidationRule } from '@/models/validation/validation-rule.interface';
import { IUpload } from '@/models/upload.interface';

export default new (class RowsFoundRule implements IValidationRule {
	public trackingValidationError = ValidationErrors.NO_ROWS;

	public get errorMessage(): string {
		const upperMessage = `The file you submitted <strong>does not contain any rows</strong>.`;
		const lowerMessage = `Please check your file and submit it again.`;
		return `${upperMessage} ${lowerMessage}`;
	}

	public doesUploadBreakRule(upload: IUpload): boolean {
		return upload.records.length === 0;
	}
})();
