import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelText as _vModelText, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  ref: "modalContent",
  id: "ReviewUploadModal"
}
const _hoisted_2 = { class: "ReviewUploadHeaderBlock" }
const _hoisted_3 = { class: "ReviewUploadProgressIndicator" }
const _hoisted_4 = { class: "ReviewUploadContentBlock" }
const _hoisted_5 = {
  class: "ReviewUploadQuestionBlock",
  "data-cy": "QuestionGeoScope"
}
const _hoisted_6 = ["id", "value"]
const _hoisted_7 = ["for"]
const _hoisted_8 = {
  class: "ReviewUploadQuestionBlock",
  "data-cy": "QuestionCommissionType"
}
const _hoisted_9 = ["id", "value"]
const _hoisted_10 = ["for"]
const _hoisted_11 = { class: "ReviewUploadButtonWrapper" }
const _hoisted_12 = {
  class: "ReviewUploadQuestionBlock",
  "data-cy": "QuestionFileContents"
}
const _hoisted_13 = ["id", "value"]
const _hoisted_14 = ["for"]
const _hoisted_15 = {
  key: 0,
  class: "ReviewUploadQuestionBlock",
  "data-cy": "QuestionNumberOfEnrolments"
}
const _hoisted_16 = { class: "ReviewUploadButtonWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[7] || (_cache[7] = _createElementVNode("h3", null, "Review upload", -1 /* HOISTED */)),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.modalReviewUploadState) + "/2 ", 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.isModalStepUploadScopeAndType)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _cache[10] || (_cache[10] = _createElementVNode("p", { class: "ReviewUploadTextBlock" }, " Thank you for your submission! Before you go, please help us better understand what you submitted. ", -1 /* HOISTED */)),
            _createElementVNode("fieldset", _hoisted_5, [
              _cache[8] || (_cache[8] = _createElementVNode("legend", { class: "ReviewUploadTitle" }, " Which type of enrolments? (Geo Scope) ", -1 /* HOISTED */)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.geoScopeOptions, (option) => {
                return (_openBlock(), _createElementBlock("div", null, [
                  _withDirectives(_createElementVNode("input", {
                    type: "radio",
                    name: "geoScope",
                    class: "ReviewUploadRadioButton",
                    id: option.id,
                    value: option.value,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedGeoScope) = $event))
                  }, null, 8 /* PROPS */, _hoisted_6), [
                    [_vModelRadio, _ctx.selectedGeoScope]
                  ]),
                  _createElementVNode("label", {
                    for: option.id,
                    class: "ReviewUploadQuestionText"
                  }, _toDisplayString(option.label), 9 /* TEXT, PROPS */, _hoisted_7)
                ]))
              }), 256 /* UNKEYED_FRAGMENT */))
            ]),
            _createElementVNode("fieldset", _hoisted_8, [
              _cache[9] || (_cache[9] = _createElementVNode("legend", { class: "ReviewUploadTitle" }, " What commission type? (Agents vs. Direct) ", -1 /* HOISTED */)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.commissionTypeOptions, (option) => {
                return (_openBlock(), _createElementBlock("div", null, [
                  _withDirectives(_createElementVNode("input", {
                    type: "radio",
                    name: "commissionType",
                    class: "ReviewUploadRadioButton",
                    id: option.id,
                    value: option.value,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCommissionType) = $event))
                  }, null, 8 /* PROPS */, _hoisted_9), [
                    [_vModelRadio, _ctx.selectedCommissionType]
                  ]),
                  _createElementVNode("label", {
                    for: option.id,
                    class: "ReviewUploadQuestionText"
                  }, _toDisplayString(option.label), 9 /* TEXT, PROPS */, _hoisted_10)
                ]))
              }), 256 /* UNKEYED_FRAGMENT */))
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("button", {
                class: "DriverButton ReviewUploadButton",
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showUploadContentsScreen && _ctx.showUploadContentsScreen(...args)))
              }, " Next ")
            ])
          ], 64 /* STABLE_FRAGMENT */))
        : (_ctx.isModalStepUploadContents)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _cache[16] || (_cache[16] = _createElementVNode("div", { class: "WarningContainer" }, [
                _createElementVNode("i", { class: "lnr-info WarningIcon" }),
                _createElementVNode("div", { class: "WarningMessage" }, " Please confirm if you have uploaded all email addresses for each enrolment, including any additional emails. ")
              ], -1 /* HOISTED */)),
              _createElementVNode("fieldset", _hoisted_12, [
                _cache[11] || (_cache[11] = _createElementVNode("legend", { class: "ReviewUploadTitle" }, " This file contains: ", -1 /* HOISTED */)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileContentsOptions, (option) => {
                  return (_openBlock(), _createElementBlock("div", null, [
                    _withDirectives(_createElementVNode("input", {
                      type: "radio",
                      name: "fileContents",
                      class: "ReviewUploadRadioButton",
                      id: option.id,
                      value: option.value,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedFileContents) = $event))
                    }, null, 8 /* PROPS */, _hoisted_13), [
                      [_vModelRadio, _ctx.selectedFileContents]
                    ]),
                    _createElementVNode("label", {
                      for: option.id,
                      class: "ReviewUploadQuestionText"
                    }, _toDisplayString(option.label), 9 /* TEXT, PROPS */, _hoisted_14)
                  ]))
                }), 256 /* UNKEYED_FRAGMENT */))
              ]),
              (_ctx.isSelectedFileContentsMultiple)
                ? (_openBlock(), _createElementBlock("fieldset", _hoisted_15, [
                    _cache[12] || (_cache[12] = _createElementVNode("legend", { class: "ReviewUploadTitle" }, " Number of unique enrolments in the file: ", -1 /* HOISTED */)),
                    _cache[13] || (_cache[13] = _createElementVNode("div", { class: "ReviewUploadInputNote" }, " *If unknown, leave blank ", -1 /* HOISTED */)),
                    _cache[14] || (_cache[14] = _createElementVNode("label", {
                      class: "ReviewUploadQuestionText",
                      for: "ReviewUploadNumberOfEnrolments"
                    }, " Unique Enrolments: ", -1 /* HOISTED */)),
                    _withDirectives(_createElementVNode("input", {
                      type: "number",
                      id: "ReviewUploadNumberOfEnrolments",
                      class: "ReviewUploadNumberInput",
                      placeholder: "Insert number",
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.numberOfUniqueEnrolments) = $event))
                    }, null, 512 /* NEED_PATCH */), [
                      [_vModelText, _ctx.numberOfUniqueEnrolments]
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("button", {
                  class: "NavigationBackButton",
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.showUploadScopeAndTypeScreen && _ctx.showUploadScopeAndTypeScreen(...args)))
                }, _cache[15] || (_cache[15] = [
                  _createElementVNode("i", { class: "lnr-arrow-left NavigationBackButtonIcon" }, null, -1 /* HOISTED */),
                  _createTextVNode(" Previous Question ")
                ])),
                _createElementVNode("button", {
                  class: "DriverButton ReviewUploadButton",
                  onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                }, " Next ")
              ])
            ], 64 /* STABLE_FRAGMENT */))
          : _createCommentVNode("v-if", true)
    ])
  ], 512 /* NEED_PATCH */))
}