import { computed, ref, Ref } from 'vue';
import { ComplexModal } from '@studyportals/modal';
import { ModalReviewUploadState } from '@/models/modal/modal.enum';
import { IReviewUploadOption, ReviewUploadQuestionCategories, GeoScopeOptions, CommissionTypeOptions, FileContentsOptions }
	from '@/models/review-upload.interface';
import RedshiftTracker from '@/infrastructure/redshift-tracker';
import store from '@/store';

export default class ReviewUploadModalComponent {
	public trackingCompleted = ref(false);
	public selectedGeoScope: Ref<null | GeoScopeOptions> = ref(null);
	public selectedCommissionType: Ref<null | CommissionTypeOptions> = ref(null);
	public selectedFileContents: Ref<null | FileContentsOptions> = ref(null);
	public numberOfUniqueEnrolments: Ref<null | number> = ref(null);
	public modalContent: Ref<HTMLElement | null> = ref(null);
	private modal: Ref<ComplexModal | undefined> = ref(undefined);

	public get geoScopeOptions(): IReviewUploadOption[] {
		return [
			{ value: GeoScopeOptions.ALL, label: 'All enrolments', id: 'GeoScopeAll' },
			{ value: GeoScopeOptions.INTERNATIONAL, label: 'International enrolments only', id: 'GeoScopeInternational' },
			{ value: GeoScopeOptions.DOMESTIC, label: 'Domestic enrolments only', id: 'GeoScopeDomestic' },
			{ value: GeoScopeOptions.UNSURE, label: 'I don\'t know', id: 'GeoScopeUnsure' }
		];
	}

	public get commissionTypeOptions(): IReviewUploadOption[] {
		return [
			{ value: CommissionTypeOptions.COMBINATION, label: 'Direct and Agent assisted enrolments', id: 'CommissionTypeCombination' },
			{ value: CommissionTypeOptions.AGENT, label: 'Only Agent Assists', id: 'CommissionTypeAgent' },
			{ value: CommissionTypeOptions.DIRECT, label: 'Only Direct enrolments', id: 'CommissionTypeDirect' },
			{ value: CommissionTypeOptions.UNSURE, label: 'I don\'t know', id: 'CommissionTypeUnsure' }
		];
	}

	public get fileContentsOptions(): IReviewUploadOption[] {
		return [
			{ value: FileContentsOptions.ADDITIONAL, label: 'One email address, but I have additional', id: 'FileContentsAdditional' },
			{ value: FileContentsOptions.MULTIPLE, label: 'Multiple email addresses per enrolment', id: 'FileContentsMultiple' },
			{ value: FileContentsOptions.ONE, label: 'I only have one email address per enrolment', id: 'FileContentsOne' },
			{ value: FileContentsOptions.UNSURE, label: 'I don\'t know', id: 'FileContentsUnsure' }
		];
	}

	private get redshiftTracker(): RedshiftTracker {
		return store.getters.redshiftTracker();
	}

	public isSelectedFileContentsMultiple = computed((): boolean => {
		return this.selectedFileContents.value === FileContentsOptions.MULTIPLE;
	});

	public isModalStepUploadScopeAndType = computed((): boolean => {
		return this.modalReviewUploadState.value === ModalReviewUploadState.UPLOAD_SCOPE_AND_TYPE;
	});

	public isModalStepUploadContents = computed((): boolean => {
		return this.modalReviewUploadState.value === ModalReviewUploadState.UPLOAD_CONTENTS;
	});

	public modalReviewUploadState = computed((): ModalReviewUploadState => {
		return store.getters.modalReviewUploadState();
	});

	public mounted(): void {
		this.trackingCompleted.value = false;
		this.prepareModal();
		this.redshiftTracker.trackReviewUploadModalOpened();
		this.redshiftTracker.trackReviewUploadDetails();
	}

	public unmounted(): void {
		window.ModalManager.close(this.modal.value);
	}

	public closeModal(): void {
		this.storeQuestionnaireAnswersIfRelevant();
		store.mutations.setModalReviewUploadState(ModalReviewUploadState.INACTIVE);
	}

	public showUploadScopeAndTypeScreen(): void {
		store.mutations.setModalReviewUploadState(ModalReviewUploadState.UPLOAD_SCOPE_AND_TYPE);
	}

	public showUploadContentsScreen(): void {
		store.mutations.setModalReviewUploadState(ModalReviewUploadState.UPLOAD_CONTENTS);
	}

	private storeQuestionnaireAnswersIfRelevant(): void {
		// Ensure that tracking is never triggered twice (e.g. when the modal is closed in different ways).
		if (this.trackingCompleted.value) {
			return;
		}

		this.storeGeoScopeAnswerIfRelevant();
		this.storeCommissionTypeAnswerIfRelevant();
		this.storeFileContentsAnswerIfRelevant();
		this.storeNumberOfEnrolmentsAnswerIfRelevant();
		this.trackingCompleted.value = true;
	}

	private prepareModal(): void {
		if (!this.modalContent.value) {
			return;
		}

		this.modal.value = new ComplexModal(this.modalContent.value, {
			cssClassName: 'ReviewUploadModalWrapper',
			destroyOnClose: false,
			onClose: this.closeModal.bind(this)
		});

		window.ModalManager.open(this.modal.value);
	}

	private storeGeoScopeAnswerIfRelevant(): void {
		if (!this.selectedGeoScope.value) {
			return;
		}

		this.redshiftTracker.trackReviewUploadAnswer(
			ReviewUploadQuestionCategories.GEO_SCOPE,
			this.selectedGeoScope.value
		);
	}

	private storeCommissionTypeAnswerIfRelevant(): void {
		if (!this.selectedCommissionType.value) {
			return;
		}

		this.redshiftTracker.trackReviewUploadAnswer(
			ReviewUploadQuestionCategories.COMMISSION_TYPE,
			this.selectedCommissionType.value
		);
	}

	private storeFileContentsAnswerIfRelevant(): void {
		if (!this.selectedFileContents.value) {
			return;
		}

		this.redshiftTracker.trackReviewUploadAnswer(
			ReviewUploadQuestionCategories.FILE_CONTENTS,
			this.selectedFileContents.value
		);
	}

	private storeNumberOfEnrolmentsAnswerIfRelevant(): void {
		if (typeof this.numberOfUniqueEnrolments.value !== 'number') {
			return;
		}

		this.redshiftTracker.trackReviewUploadAnswer(
			ReviewUploadQuestionCategories.NUMBER_OF_ENROLMENTS,
			this.numberOfUniqueEnrolments.value.toString()
		);
	}
}
