import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { id: "InvalidRecordsOverview" }
const _hoisted_2 = { class: "InvalidRecordsList" }
const _hoisted_3 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.invalidRecords, (record) => {
        return (_openBlock(), _createElementBlock("li", {
          class: "InvalidRecordDetails",
          title: record.value
        }, " Row " + _toDisplayString(record.row + 1) + ": " + _toDisplayString(record.value), 9 /* TEXT, PROPS */, _hoisted_3))
      }), 256 /* UNKEYED_FRAGMENT */))
    ])
  ]))
}