import store from '@/store';
import { ISMTOrganisationDetails } from '@/models/organisation-details.interface';
import lurchClient from '@/infrastructure/lurch-client';

class OrganisationDetailsHandler {
	public async prepareOrganisationDetails(): Promise<void> {
		const organisationDetails = await lurchClient.retrieveOrganisationsFromUser();
		this.storeOrganisationDetails(organisationDetails);
	}

	private storeOrganisationDetails(details: ISMTOrganisationDetails): void {
		store.mutations.setOrganisationDetails(details);

		if (details.organisations.length !== 1) {
			return;
		}

		store.mutations.setOrganisationId(details.organisations[0].identity);
	}
}

export default new OrganisationDetailsHandler();
