import { computed, watch } from 'vue';
import { SubmitUploadStatuses } from '@/models/submit-upload-statuses.enum';
import { Routes } from '@/models/router.interface';
import { IntakeStageType } from '@studyportals/sp-lurch-interface';
import { IUpload } from '@/models/upload.interface';
import { ISMTOrganisationDetails } from '@/models/organisation-details.interface';
import { ModalReviewUploadState } from '@/models/modal/modal.enum';
import EncryptionHandler from '@/services/encryption-handler';
import lurchClient from '@/infrastructure/lurch-client';
import store from '@/store';

class SubmitUploadHandler {
	private timingBetweenMatchingProgressUpdates = 3500;

	private get currentYear(): number {
		return new Date().getFullYear();
	}

	private get currentMonth(): number {
		return new Date().getMonth();
	}

	private get upload(): IUpload | undefined {
		return store.getters.upload();
	}

	private get organisationId(): string {
		return store.getters.organisationId();
	}

	private get organisationDetails(): ISMTOrganisationDetails {
		return store.getters.organisationDetails();
	}

	private get recruitmentStage(): IntakeStageType {
		return store.getters.recruitmentStage();
	}

	private get intakeMonth(): number | undefined {
		return store.getters.intakeMonth();
	}

	private get intakeYear(): number | undefined {
		return store.getters.intakeYear();
	}

	private get submitUploadStatus(): SubmitUploadStatuses {
		return store.getters.submitUploadStatus();
	}

	private modalReviewUploadState = computed((): ModalReviewUploadState => {
		return store.getters.modalReviewUploadState();
	});

	public async handleUploadSubmit(): Promise<void> {
		const submissionResult = await this.triggerUploadSubmission();
		if (typeof submissionResult !== 'number') {
			this.uponFailureNavigateAfterDelay();
			return;
		}

		this.updateSubmitUploadStatusAfterSubmitting(submissionResult);
	}

	private async triggerUploadSubmission(): Promise<number | undefined> {
		if (!this.upload) {
			return;
		}

		const hashedRecords = await EncryptionHandler.encryptRecords(this.upload.records);
		if (this.submitUploadStatus === SubmitUploadStatuses.ANONYMISING_FAILED) {
			return;
		}

		return await this.submitUpload(hashedRecords);
	}

	private async submitUpload(hashedRecords: string): Promise<number | undefined> {
		// For leads intake dates can be skipped, so just take the current year and month for the submit.
		const intakeYear = this.intakeYear ? this.intakeYear : this.currentYear;
		const intakeMonth = this.intakeMonth ? this.intakeMonth : this.currentMonth;

		this.setSubmitUploadStatusAsSubmitting();

		try {
			return await lurchClient.submitEnrollments(
				this.organisationId,
				this.organisationDetails.userId.toString(),
				this.recruitmentStage,
				intakeMonth,
				intakeYear,
				hashedRecords
			);
		} catch (e) {
			this.setSubmitUploadStatusAsFailure();
			return;
		}
	}

	private uponFailureNavigateAfterDelay(): void {
		setTimeout(() => {
			this.navigateToLastPage();
		}, this.timingBetweenMatchingProgressUpdates);
	}

	private updateSubmitUploadStatusAfterSubmitting(submissionResult: number): void {
		this.setSubmitUploadStatusAsMatching();
		this.setSubmitUploadStatusAsAwaitingResultAfterDelay();
		this.showReviewUploadModalAfterDelay(submissionResult);
	}

	private showReviewUploadModalAfterDelay(submissionResult: number): void {
		setTimeout(() => {
			this.showReviewUploadModal();

			const unwatchReviewUploadState = watch(this.modalReviewUploadState, (newValue) => {
				if (newValue !== ModalReviewUploadState.INACTIVE) {
					return
				}

				this.wrapUpUploadSubmitAfterDelay(submissionResult);
				unwatchReviewUploadState();
			});
		}, this.timingBetweenMatchingProgressUpdates);
	}

	private wrapUpUploadSubmitAfterDelay(submissionResult: number): void {
		setTimeout(() => {
			this.updateSubmitUploadStatusBasedOnOutcome(submissionResult);
			this.navigateToLastPage();
		}, this.timingBetweenMatchingProgressUpdates);
	}

	private updateSubmitUploadStatusBasedOnOutcome(submissionResult: number): void {
		if (submissionResult === 0) {
			this.setSubmitUploadStatusAsNoMatchesFound();
		} else {
			this.setSubmitUploadStatusAsMatchesFound();
		}
	}

	private navigateToLastPage(): void {
		store.mutations.setCurrentRoute(Routes.END);
	}

	private setSubmitUploadStatusAsAwaitingResultAfterDelay(): void {
		setTimeout(() => {
			store.mutations.setSubmitUploadStatus(SubmitUploadStatuses.AWAITING_RESULT);
		}, this.timingBetweenMatchingProgressUpdates);
	}

	private setSubmitUploadStatusAsSubmitting(): void {
		store.mutations.setSubmitUploadStatus(SubmitUploadStatuses.SUBMITTING);
	}

	private setSubmitUploadStatusAsMatching(): void {
		store.mutations.setSubmitUploadStatus(SubmitUploadStatuses.MATCHING);
	}

	private setSubmitUploadStatusAsMatchesFound(): void {
		store.mutations.setSubmitUploadStatus(SubmitUploadStatuses.MATCHES_FOUND);
	}

	private setSubmitUploadStatusAsNoMatchesFound(): void {
		store.mutations.setSubmitUploadStatus(SubmitUploadStatuses.NO_MATCHES_FOUND);
	}

	private setSubmitUploadStatusAsFailure(): void {
		store.mutations.setSubmitUploadStatus(SubmitUploadStatuses.SUBMITTING_FAILED);
	}

	private showReviewUploadModal(): void {
		store.mutations.setModalReviewUploadState(ModalReviewUploadState.UPLOAD_SCOPE_AND_TYPE);
	}
}

export default new SubmitUploadHandler();
