import { computed } from 'vue';
import { IUpload } from '@/models/upload.interface';
import UploadPresenter from '@/presenters/upload-presenter';
import store from '@/store';

export default class UploadOverviewComponent {
	public pageTitle = computed((): string => {
		return this.upload.value ? 'File validation done' : 'Student Data';
	});

	public upload = computed((): IUpload | undefined => {
		return store.getters.upload();
	});

	public recruitmentStageAsText = computed((): string => {
		const text = UploadPresenter.retrieveRecruitmentStageAsText();
		if (!text) {
			return '';
		}

		return `for the <strong>${text}</strong>`;
	});

	public intakePeriodAsText = computed((): string => {
		const text = UploadPresenter.retrieveIntakePeriodAsText(false);
		if (!text) {
			return '';
		}

		return `&nbsp;for ${text}`;
	});
}
