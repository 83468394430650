import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "PageMatching",
  class: "SMTPage"
}
const _hoisted_2 = { class: "SMTPageContentBlock" }
const _hoisted_3 = { class: "SMTText" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MatchesProgressOverview = _resolveComponent("MatchesProgressOverview")!
  const _component_ReviewUploadModal = _resolveComponent("ReviewUploadModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.submitUploadNotFinishedYet)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.submitUploadInProgress)
            ? (_openBlock(), _createBlock(_component_MatchesProgressOverview, { key: 0 }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("div", _hoisted_2, [
                  _cache[2] || (_cache[2] = _createElementVNode("h1", { class: "SMTTitle" }, "Match", -1 /* HOISTED */)),
                  _createElementVNode("p", _hoisted_3, [
                    _cache[0] || (_cache[0] = _createTextVNode(" You are about to anonymise and match ")),
                    _createElementVNode("span", { innerHTML: _ctx.recruitmentStageAsText }, null, 8 /* PROPS */, _hoisted_4),
                    _createElementVNode("span", { innerHTML: _ctx.intakePeriodAsText }, null, 8 /* PROPS */, _hoisted_5),
                    _cache[1] || (_cache[1] = _createTextVNode(". "))
                  ]),
                  _cache[3] || (_cache[3] = _createElementVNode("p", { class: "SMTText SMTAdditionalText" }, " Before sending the data to the Studyportals servers, it is irreversibly anonymised according to the General Data Protection Regulation (GDPR) and our mutual Data Processing Agreement (DPA). ", -1 /* HOISTED */))
                ]),
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "SMTPageContentBlock" }, [
                  _createElementVNode("img", {
                    class: "SMTPageImage",
                    src: "https://monet-prtl-co.imgix.net/Endor/SMT/matching-visual.svg"
                  })
                ], -1 /* HOISTED */))
              ], 64 /* STABLE_FRAGMENT */))
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true),
    (_ctx.shouldModalBeShown)
      ? (_openBlock(), _createBlock(_component_ReviewUploadModal, { key: 1 }))
      : _createCommentVNode("v-if", true)
  ]))
}