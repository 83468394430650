import { Component } from 'vue';

enum Routes {
	START = 'Welcome',
	RECRUITMENT_STAGE = 'Recruitment Stage',
	INTAKE_PERIOD = 'Intake Period',
	UPLOAD = 'Student Data',
	MATCHING = 'Match',
	END = 'End'
}

interface IRouter {
	current: Routes;
	props: {
		[key: string]: any;
	};
}

type TRoutes = {
	[key in Routes]: Component;
};

export { IRouter, TRoutes, Routes };
