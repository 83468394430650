import { computed } from 'vue';
import { IRecruitmentStageDetails } from '@/models/recruitment-stage-details.interface';
import { IntakeStageType } from '@studyportals/sp-lurch-interface';
import store from '@/store';

export default class RecruitmentStageComponent {
	public stages: IRecruitmentStageDetails[] = [
		{
			type: IntakeStageType.LEAD,
			title: 'Leads',
			image: 'https://monet-prtl-co.imgix.net/Endor/SMT/recruitment-stage-leads.svg',
			description: 'Students who have filled out an enquiry form on your website'
		},
		{
			type: IntakeStageType.APPLICATION,
			title: 'Applications',
			image: 'https://monet-prtl-co.imgix.net/Endor/SMT/recruitment-stage-applications.svg',
			description: 'Students who applied to study at your institution'
		},
		{
			type: IntakeStageType.ACCEPTED,
			title: 'Offers',
			image: 'https://monet-prtl-co.imgix.net/Endor/SMT/recruitment-stage-offers.svg',
			description: 'Students who received an offer, but have not yet enrolled'
		},
		{
			type: IntakeStageType.ENROLMENT,
			title: 'Enrolments',
			image: 'https://monet-prtl-co.imgix.net/Endor/SMT/recruitment-stage-enrolments.svg',
			description: 'Students who have enrolled at your institution'
		}
	];

	private selectedStage = computed((): IntakeStageType => {
		return store.getters.recruitmentStage();
	});

	public setStage(stage: IntakeStageType): void {
		store.mutations.setRecruitmentStage(stage);
	}

	public isStageSelected(stage: IntakeStageType): boolean {
		return stage === this.selectedStage.value;
	}
}
