import { Component, defineComponent, onMounted } from 'vue';
import AppComponent from '@/presentation/app-class';
import Navigation from '@/presentation/components/navigation/navigation.vue';
import NavigationStatus from '@/presentation/components/navigation-status/navigation-status.vue';
import FAQ from '@/presentation/components/faq/faq.vue';
import LogOut from '@/stand-alone/log-out/log-out.vue';

export default defineComponent({
	components: {
		Navigation: Navigation as Component,
		NavigationStatus: NavigationStatus as Component,
		FAQ: FAQ as Component,
		LogOut: LogOut as Component
	},
	setup: () => {
		const component = new AppComponent();

		onMounted(component.mounted.bind(component));

		return {
			standAloneLogin: component.standAloneLogin,
			router: component.router,
			CurrentComponent: component.CurrentComponent
		};
	}
});
