class SingleRecordValidationHandler {
	private hashedRecordLength = 128;

	public isRecordEmail(record: string): boolean {
		// eslint-disable-next-line max-len
		const regex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return regex.test(record);
	}

	public isRecordHashed(record: string): boolean {
		// If the record has a length of exactly 128 records, it is most likely a hashed email.
		return record.length === this.hashedRecordLength;
	}
}

export default new SingleRecordValidationHandler();
