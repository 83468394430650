import { defineComponent } from 'vue';
import NavigationComponent from '@/presentation/components/navigation/navigation-class';

export default defineComponent({
	setup: () => {
		const component = new NavigationComponent();

		return {
			nextButtonText: component.nextButtonText,
			isStepBackAvailable: component.isStepBackAvailable,
			isSkipAvailable: component.isSkipAvailable,
			areStepsAvailable: component.areStepsAvailable,
			isNextDisabled: component.isNextDisabled,
			stepBack: component.stepBack.bind(component),
			stepForward: component.stepForward.bind(component)
		};
	}
});
