import { computed } from 'vue';
import { SubmitUploadStatuses } from '@/models/submit-upload-statuses.enum';
import { MatchingErrors } from '@/models/tracking/matching-errors.enum';
import { Routes } from '@/models/router.interface';
import UploadPresenter from '@/presenters/upload-presenter';
import RedshiftTracker from '@/infrastructure/redshift-tracker';
import store from '@/store';

export default class EndComponent {
	private get redshiftTracker(): RedshiftTracker {
		return store.getters.redshiftTracker();
	}

	public intakePeriodAsText = computed((): string => {
		const text = UploadPresenter.retrieveIntakePeriodAsText(false);
		if (!text) {
			return '';
		}

		return `&nbsp;for the intake period ${text}`;
	});

	public recruitmentStageAsText = computed((): string => {
		return UploadPresenter.retrieveRecruitmentStageWithAmountAsText();
	});

	public matchesFound = computed((): boolean => {
		return this.submitUploadStatus.value === SubmitUploadStatuses.MATCHES_FOUND;
	});

	public noMatchesFound = computed((): boolean => {
		return this.submitUploadStatus.value === SubmitUploadStatuses.NO_MATCHES_FOUND;
	});

	public submitFailed = computed((): boolean => {
		return this.submitFailedOnAnonymisation.value || this.submitFailedOnSubmitting.value;
	});

	private submitFailedOnAnonymisation = computed((): boolean => {
		return this.submitUploadStatus.value === SubmitUploadStatuses.ANONYMISING_FAILED;
	});

	private submitFailedOnSubmitting = computed((): boolean => {
		return this.submitUploadStatus.value === SubmitUploadStatuses.SUBMITTING_FAILED;
	});

	private submitUploadStatus = computed((): SubmitUploadStatuses => {
		return store.getters.submitUploadStatus();
	});

	public mounted(): void {
		this.trackMatchingOutcome();
	}

	public refreshTool(): void {
		this.redshiftTracker.trackRestartFlow();

		store.mutations.setSubmitUploadStatus(SubmitUploadStatuses.INACTIVE);
		store.mutations.resetInput();
		store.mutations.setCurrentRoute(Routes.START);
	}

	private trackMatchingOutcome(): void {
		if (this.submitFailedOnAnonymisation.value) {
			this.redshiftTracker.trackMatchingError(MatchingErrors.ANONYMISATION);
		} else if (this.submitFailedOnSubmitting.value) {
			this.redshiftTracker.trackMatchingError(MatchingErrors.SUBMITTING);
		} else {
			this.redshiftTracker.trackMatchingCompletion();
		}
	}
}
