import store from '@/store';

import { ValidationErrors } from '@/models/tracking/validation-errors.enum';
import { IValidationRule } from '@/models/validation/validation-rule.interface';
import { IValidationResult } from '@/models/validation/validation-result.interface';
import { IUpload } from '@/models/upload.interface';

import RedshiftTracker from '@/infrastructure/redshift-tracker';
import FileIsCsvRule from '@/services/validation/file-is-csv-rule';
import OnlyOneColumnRule from '@/services/validation/only-one-column-rule';
import RowsFoundRule from '@/services/validation/rows-found-rule';
import NotTooManyRowsRule from '@/services/validation/not-too-many-rows-rule';
import AllEmailAddressesRule from '@/services/validation/all-email-addresses-rule';

export default class ValidationHandler {
	private rules: IValidationRule[];

	private get redshiftTracker(): RedshiftTracker {
		return store.getters.redshiftTracker();
	}

	constructor() {
		this.rules = [FileIsCsvRule, OnlyOneColumnRule, RowsFoundRule, NotTooManyRowsRule, AllEmailAddressesRule];
	}

	public validate(upload: IUpload): IUpload {
		upload.records = this.removeEmptyRows(upload.records);
		upload.records = this.removeSpacesAroundRows(upload.records);

		const brokenRule = this.rules.find((rule) => rule.doesUploadBreakRule(upload));

		if (brokenRule) {
			upload.validation = this.createFailedValidationResult(brokenRule);
			this.trackValidationError(brokenRule, upload);
		}

		return upload;
	}

	private createFailedValidationResult(rule: IValidationRule): IValidationResult {
		return {
			message: rule.errorMessage,
			invalidRecords: rule.invalidRecords ? rule.invalidRecords : []
		};
	}

	private removeEmptyRows(rows: string[]): string[] {
		return rows.filter((row) => row.length > 0);
	}

	private removeSpacesAroundRows(rows: string[]): string[] {
		return rows.map((row) => row.trim());
	}

	private trackValidationError(rule: IValidationRule, upload: IUpload): void {
		let extra;
		if (rule.invalidRecords) {
			extra = `${rule.invalidRecords.length}_${upload.records.length}`;
		}

		if (rule.trackingValidationError === ValidationErrors.TOO_MANY_ROWS) {
			extra = `${upload.records.length}`;
		}

		this.redshiftTracker.trackValidationError(rule.trackingValidationError, extra);
	}
}
