import { computed, ref } from 'vue';
import {
	IFAQQuestionInfo,
	IFAQQuestionInfoCluster,
} from '@/models/faq-question-info.interface';
import { Routes } from '@/models/router.interface';
import store from '@/store';
import CookieHandler from '@/infrastructure/cookie-handler';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const faqQuestions = require('./../../../../static/data/faq-questions.json') as IFAQQuestionInfoCluster;

export default class FaqComponent {
	public contentOpened = ref(false);
	public selectedQuestion = ref(-1);

	public get questions(): IFAQQuestionInfo[] {
		return faqQuestions.data;
	}

	public async replaceUrls(event: MouseEvent): Promise<void> {
		const target = event.target as HTMLAnchorElement;
		if (!target.href) {
			return;
		}

		const currentUrl = target.href;
		if (currentUrl.includes('retrieve-s3-signed-url-by-url')) {
			try {
				const response = await fetch(currentUrl, {
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': CookieHandler.retrieveTokenIdCookie(),
					}
				});

				const json = await response.json() as { s3SignedUrl: string };
				const temporaryLink = json.s3SignedUrl;

				window.open(temporaryLink, '_blank');
			} catch (error) {
				if (error instanceof Error) {
					throw new Error(`Retrieve s3 item failed: ${error.message}`);
				}

				throw new Error(`Unexpected error in replaceUrls: ${error as string}`);
			}
		} else {
			window.open(currentUrl, '_blank');
		}
	}

	public get uniAdminUrlFAQ(): string {
		const uniAdminUrl = webpackDefinitions.VUE_UNI_ADMIN_URL;
		return `${uniAdminUrl}support/faq/`;
	}

	public isOnStartPage = computed((): boolean => {
		const router = store.getters.router();
		return router.current === Routes.START;
	});

	public toggleContent(): void {
		this.contentOpened.value = !this.contentOpened.value;
	}

	public updateSelectedQuestion(index: number): void {
		this.selectedQuestion.value = this.selectedQuestion.value === index ? -1 : index;
	}
}
