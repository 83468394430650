import { computed } from 'vue';
import { IUpload } from '@/models/upload.interface';
import { IInvalidRecordDetails } from '@/models/validation/invalid-record-details.interface';
import store from '@/store';

export default class InvalidRecordsOverviewComponent {
	public invalidRecords = computed((): IInvalidRecordDetails[] => {
		if (!this.upload.value || !this.upload.value.validation) {
			return [];
		}

		const records = this.upload.value.records;

		return this.upload.value.validation.invalidRecords.map((row: number) => ({
			row,
			value: records.length > row ? records[row] : ''
		}));
	});

	private upload = computed((): IUpload | undefined => {
		return store.getters.upload();
	});
}
