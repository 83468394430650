import SingleSignOnSessionManager from '@/stand-alone/single-sign-on-session-manager';
import store from '@/store';

export default class LogOutComponent {
	private singleSignOnSessionManager = new SingleSignOnSessionManager();

	public hideWidget(): void {
		store.mutations.setStandAloneLogin(false);
	}

	public signOut(): void {
		this.singleSignOnSessionManager.logout();
	}
}
