import { computed } from 'vue';
import { SubmitUploadStatuses } from '@/models/submit-upload-statuses.enum';
import { ModalReviewUploadState } from '@/models/modal/modal.enum';
import UploadPresenter from '@/presenters/upload-presenter';
import store from '@/store';

export default class MatchingComponent {
	public submitUploadInProgress = computed((): boolean => {
		return this.submitUploadStatus.value !== SubmitUploadStatuses.INACTIVE && this.submitUploadNotFinishedYet.value;
	});

	public shouldModalBeShown = computed((): boolean => {
		return this.modalReviewUploadState.value !== ModalReviewUploadState.INACTIVE;
	});

	private modalReviewUploadState = computed((): ModalReviewUploadState => {
		return store.getters.modalReviewUploadState();
	});

	public submitUploadNotFinishedYet = computed((): boolean => {
		return (
			this.submitUploadStatus.value !== SubmitUploadStatuses.MATCHES_FOUND &&
			this.submitUploadStatus.value !== SubmitUploadStatuses.NO_MATCHES_FOUND
		);
	});

	public recruitmentStageAsText = computed((): string => {
		return UploadPresenter.retrieveRecruitmentStageWithAmountAsText();
	});

	public intakePeriodAsText = computed((): string => {
		const text = UploadPresenter.retrieveIntakePeriodAsText(false);
		if (!text) {
			return '';
		}

		return `&nbsp;for the intake period ${text}`;
	});

	private submitUploadStatus = computed((): SubmitUploadStatuses => {
		return store.getters.submitUploadStatus();
	});
}
