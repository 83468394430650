import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "NavigationStatus" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isCurrentRouteNavigationStep)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.steps, (step) => {
          return (_openBlock(), _createElementBlock("div", {
            key: step,
            class: _normalizeClass([
					'NavigationStatusBlock',
					_ctx.isStepSelected(step) ? 'Selected' : ''
				])
          }, _toDisplayString(step), 3 /* TEXT, CLASS */))
        }), 128 /* KEYED_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ]))
}