import { reactive } from 'vue';
import { SubmitUploadStatuses } from '@/models/submit-upload-statuses.enum';
import { IntakeStageType } from '@studyportals/sp-lurch-interface';
import { IState } from '@/models/store/state.interface';
import { Routes } from '@/models/router.interface';
import { ModalReviewUploadState } from '@/models/modal/modal.enum';
import ValidationHandler from '@/services/validation/validation-handler';
import RedshiftTracker from '@/infrastructure/redshift-tracker';

const state: any = reactive({
	modalReviewUploadState: ModalReviewUploadState.INACTIVE,
	organisationDetails: {
		userId: 0,
		organisations: []
	},
	organisationId: '',
	recruitmentStage: IntakeStageType.ENROLMENT,
	redshiftTracker: new RedshiftTracker(),
	router: {
		current: Routes.START,
		props: []
	},
	standAloneLogin: false,
	submitUploadStatus: SubmitUploadStatuses.INACTIVE,
	validationHandler: new ValidationHandler()
});

export default state as IState;
