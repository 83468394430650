import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "UploadButtonWrapper" }
const _hoisted_2 = { class: "UploadTitle" }
const _hoisted_3 = { class: "UploadButtonAlongsideIconText" }
const _hoisted_4 = {
  key: 1,
  class: "UploadButtonLabel UploadStart",
  for: "UploadButton"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "file",
      ref: "uploadButton",
      id: "UploadButton",
      class: "Hidden",
      accept: ".csv",
      onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.processUpload && _ctx.processUpload(...args)))
    }, null, 544 /* NEED_HYDRATION, NEED_PATCH */),
    (_ctx.isUploadValid)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: _normalizeClass([
					'UploadButtonLabel',
					_ctx.upload.validation ? 'UploadFailed' : 'UploadSuccess'
				]),
          for: "UploadButton"
        }, [
          _createElementVNode("span", _hoisted_2, [
            _cache[1] || (_cache[1] = _createElementVNode("i", { class: "lnr-checkmark-circle UploadIcon" }, null, -1 /* HOISTED */)),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.upload.title), 1 /* TEXT */)
          ]),
          _cache[2] || (_cache[2] = _createElementVNode("span", { class: "UploadButtonActionText NextUploadText" }, " Change file ", -1 /* HOISTED */))
        ], 2 /* CLASS */))
      : (_openBlock(), _createElementBlock("label", _hoisted_4, _cache[3] || (_cache[3] = [
          _createElementVNode("span", { class: "UploadButtonActionText FirstUploadText" }, [
            _createElementVNode("i", { class: "lnr-plus-square UploadIcon" }),
            _createElementVNode("span", { class: "UploadButtonAlongsideIconText" }, " Choose file ")
          ], -1 /* HOISTED */)
        ])))
  ]))
}