import { Component, defineComponent } from 'vue';
import MatchingComponent from '@/presentation/pages/matching/matching-class';
import MatchesProgressOverview from '@/presentation/components/matching-progress-overview/matching-progress-overview.vue';
import ReviewUploadModal from '@/presentation/components/review-upload-modal/review-upload-modal.vue';

export default defineComponent({
	components: {
		MatchesProgressOverview: MatchesProgressOverview as Component,
		ReviewUploadModal: ReviewUploadModal as Component
	},
	setup: () => {
		const component = new MatchingComponent();

		return {
			submitUploadNotFinishedYet: component.submitUploadNotFinishedYet,
			submitUploadInProgress: component.submitUploadInProgress,
			recruitmentStageAsText: component.recruitmentStageAsText,
			intakePeriodAsText: component.intakePeriodAsText,
			shouldModalBeShown: component.shouldModalBeShown
		};
	}
});
