import { ValidationErrors } from '@/models/tracking/validation-errors.enum';
import { IValidationRule } from '@/models/validation/validation-rule.interface';
import { IUpload } from '@/models/upload.interface';

export default new (class OnlyOneColumnRule implements IValidationRule {
	public trackingValidationError = ValidationErrors.MULTIPLE_COLUMNS;

	public get errorMessage(): string {
		const upperMessage = `Your file has data <strong>in more than one column</strong>.`;
		const lowerMessage = `Please check your file and submit it again.`;
		return `${upperMessage} ${lowerMessage}`;
	}

	public doesUploadBreakRule(upload: IUpload): boolean {
		/* If there are no records, this is also a validation violation of course, but that's not
		for this rule to check. There are not multiple columns, so this rule is followed. */
		if (upload.records.length === 0) {
			return false;
		}

		return upload.records[0].includes(';') || upload.records[0].includes(',');
	}
})();
