import { ValidationErrors } from '@/models/tracking/validation-errors.enum';
import { IValidationRule } from '@/models/validation/validation-rule.interface';
import { IUpload } from '@/models/upload.interface';
import SingleRecordValidationHandler from '@/services/validation/single-record-validation-handler';

export default new (class AllEmailAddressesRule implements IValidationRule {
	public invalidRecords: number[] = [];
	public trackingValidationError = ValidationErrors.INVALID_RECORDS;

	public get errorMessage(): string {
		const firstPart = `We couldn't recognize <strong>${this.invalidRecords.length} rows</strong> as <strong>email addresses</strong>.`;
		const secondPart = `Please check your file and submit it again.`;
		return `${firstPart} ${secondPart}`;
	}

	public doesUploadBreakRule(upload: IUpload): boolean {
		this.invalidRecords = [];
		upload.records.forEach(this.onInvalidAddToInvalidRecordsList.bind(this));

		return this.invalidRecords.length > 0;
	}

	private onInvalidAddToInvalidRecordsList(record: string, index: number): void {
		if (SingleRecordValidationHandler.isRecordEmail(record) || SingleRecordValidationHandler.isRecordHashed(record)) {
			return;
		}

		this.invalidRecords.push(index);
	}
})();
